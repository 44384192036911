// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};
export const SNACK_DURATION = 3000;
export const VERSION = "v1.1.0";
export const TABLE_LIMITS = [5, 10, 20, 30, 50];
export const TABLE_LIMIT_DEFAULT = TABLE_LIMITS[5];

//Maps Key
export const MAPS_API_KEY = "AIzaSyDD7KKmOyu-PAMoV0HKpzrk_PG2gGJG1v8";

//Redux

//UI
export const UI_SNACK_STATE_CHANGE = "[UI] Snack state change";
export const UI_SNACK_OPEN = "[UI] Snack open change";
export const UI_SNACK_TEXT_CHANGE = "[UI] Snack text change";

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Usuarios
export const USERS_GET_IS_SUBMITTING = "[Users] Get is submitting";
export const USERS_GET_SUCCESS = "[Users] Get success";
export const USERS_GET_FAILURE = "[Users] Get failure";
export const USERS_SET_SELECTED = "[Users] Set selected";
export const USERS_UPDATE_DOC = "[Users] Update doc";
export const USERS_REMOVE_DOC = "[Users] Delete doc";
export const USERS_SET_TOTAL_DOCS = "[Users] Set Total Docs";
export const USERS_GET_DOCS = "[Users] Get Docs";
export const USERS_SET_LAST_DOC = "[Users] Set last Doc";
export const USERS_ADD_DOCS = "[Users] Add Docs";
export const USERS_LOADING = "[Users] Is Loading";
export const USERS_FAILURE = "[Users] Failure";
export const USER_UPDATE_SUSCRIPCION_SUBMMITING = "[Users] update suscripcion  submmiting";
export const USER_UPDATE_SUSCRIPCION_SUCCESS = "[Users] update suscripcion  success";
export const USER_UPDATE_SUSCRIPCION_FAILURE = "[Users] update suscripcion  failure";
export const USER_GET_ONE_SUBMMITING = "[Users] user get one submmiting";
export const USER_GET_ONE_SUCCESS = "[Users] user get one success";
export const USER_GET_ONE_FAILURE = "[Users] user get one failure";
export const USER_UNLOCKED_SUBMMITING = "[Users] unlocked submmiting";
export const USER_UNLOCKED_SUCCESS = "[Users] unlocked success";
export const USER_UNLOCKED_FAILURE = "[Users] unlocked failure";
export const USER_BLOCKED_SUBMMITING = "[Users] blocked submmiting";
export const USER_BLOCKED_SUCCESS = "[Users] blocked success";
export const USER_BLOCKED_FAILURE = "[Users] blocked failure";

// Solicitudes
export const SOLICITUDES_GET_SUBMMITING = "[Solicitudes] Get summiting";
export const SOLICITUDES_GET_SUCCESS = "[Solicitudes] Get success";
export const SOLICITUDES_GET_FAILURE = "[Solicitudes] Get failure";
export const SOLICITUDES_SET_SELECTED = "[Solicitudes] Set selected";
export const SOLICITUDES_DELETE_SUBMMINTING = "[Solicitudes] Delete submmiting";
export const SOLICITUDES_DELETE_SUCCESS = "[Solicitudes] Delete success";
export const SOLICITUDES_DELETE_FAILURE = "[Solicitudes] Delete failure";
export const SOLICITUDES_GET_ONE_SUBMMITING = "[Solicitudes] Get one submminting";
export const SOLICITUDES_GET_ONE_SUCCESS = "[Solicitudes] Get one success";
export const SOLICITUDES_GET_ONE_FAILURE = "[Solicitudes] Get one failure";
export const SOLICITUDES_GET_DOCS = "[Solicitudes] Get docs";
export const SOLICITUDES_SET_LAST_DOC = "[Solicitudes] set last docs";
export const SOLICITUDES_LOADING = "[Solicitudes] is loading";
export const SOLICITUDES_FAILURE = "[Solicitudes] is failure";
export const SOLICITUDES_BLOQUED_SUBMMITING = "[Solicitudes] bloqued submmiting";
export const SOLICITUDES_BLOQUED_FAILURE = "[Solicitudes] bloqued Failure";
export const SOLICITUDES_BLOQUED_SUCCESS = "[Solicitudes] bloqued success";
export const SOLICITUDES_UNLOCKED_SUBMMITING = "[Solicitudes] unlocked submmiting";
export const SOLICITUDES_UNLOCKED_FAILURE = "[Solicitudes] unlocked Failure";
export const SOLICITUDES_UNLOCKED_SUCCESS = "[Solicitudes] unlocked success";

// Ofertas
export const OFFERS_GET_SUBMMITING = "[Offers] get submmiting";
export const OFFERS_GET_SUCCESS = "[Offers] get success";
export const OFFERS_GET_FAILURE = "[Offers] get failure";
export const OFFERS_SET_SELECTED = "[Offers] set selected";
export const OFFERS_GET_ONE_SUBMMITING = "[Offers] get one submmiting";
export const OFFERS_GET_ONE_SUCCESS = "[Offers] get one success";
export const OFFERS_GET_ONE_FAILURE = "[Offers] get one failure";
export const OFFERS_GET_DOCS = "[Offers] Get docs";
export const OFFERS_SET_LAST_DOC = "[Offers] set last docs";
export const OFFERS_LOADING = "[Offers] is loading";
export const OFFERS_FAILURE = "[Offers] is failure";
export const OFFERS_BLOQUED_SUBMMITING = "[Offers] bloqued submmiting";
export const OFFERS_BLOQUED_FAILURE = "[Offers] bloqued failure";
export const OFFERS_BLOQUED_SUCCESS = "[Offers] bloqued succes";
export const OFFERS_UNLOCKED_SUBMMITING = "[Offers] unlocked submmiting";
export const OFFERS_UNLOCKED_FAILURE = "[Offers] unlocked failure";
export const OFFERS_UNLOCKED_SUCCESS = "[Offers] unlocked succes";

// Categorias
export const CATEGORY_GET_SUBMITING = "[Catergory] get submmiting";
export const CATEGORY_GET_FAILURE = "[Catergory] get failure";
export const CATEGORY_GET_SUCCESS = "[Catergory] get success";
export const CATEGORY_SET_SELECTED = "[Catergory] set selected";
export const CATEGORY_GET_ONE_SUBMMITING = "[Catergory] Get one submmiting";
export const CATEGORY_GET_ONE_SUCCESS = "[Catergory] Get one success";
export const CATEGORY_GET_ONE_FAILURE = "[Catergory] Get one failure";
export const CATEGORY_DELETED_SUBMMITING = "[Catergory] deleted submmiting";
export const CATEGORY_DELETED_SUCCESS = "[Catergory] deleted success";
export const CATEGORY_DELETED_FAILURE = "[Catergory] deleted failure";
export const CATEGORY_ADD_ONE_SUBMMITING = "[Catergory] add one submmiting";
export const CATEGORY_ADD_ONE_SUCCESS = "[Catergory] add one success";
export const CATEGORY_ADD_ONE_FAILURE = "[Catergory] add one failure";
export const CATEGORY_UPDATE_ONE_FAILURE = "[Catergory] update one failure";
export const CATEGORY_UPDATE_ONE_SUCCESS = "[Catergory] update one success";
export const CATEGORY_UPDATE_ONE_SUBMMITING = "[Catergory] update one submmiting";
export const CATEGORY_LOADING = "[Catergory]  Catergory loading";
export const CATEGORY_FAILURE = "[Catergory]  Catergory failure";
// Suscripciones
export const SUSCRIPTIONS_GET_SUBMITING = "[Suscripciones] get submmiting";
export const SUSCRIPTIONS_GET_FAILURE = "[Suscripciones] get failure";
export const SUSCRIPTIONS_GET_SUCCESS = "[Suscripciones] get success";
export const SUSCRIPTIONS_SET_SELECTED = "[Suscripciones] set selected";
export const SUSCRIPTIONS_GET_ONE_SUBMMITING = "[Suscripciones] Get one submmiting";
export const SUSCRIPTIONS_GET_ONE_SUCCESS = "[Suscripciones] Get one success";
export const SUSCRIPTIONS_GET_ONE_FAILURE = "[Suscripciones] Get one failure";
export const SUSCRIPTIONS_DELETED_SUBMMITING = "[Suscripciones] deleted submmiting";
export const SUSCRIPTIONS_DELETED_SUCCESS = "[Suscripciones] deleted success";
export const SUSCRIPTIONS_DELETED_FAILURE = "[Suscripciones] deleted failure";
export const SUSCRIPTIONS_ADD_ONE_SUBMMITING = "[Suscripciones] add one submmiting";
export const SUSCRIPTIONS_ADD_ONE_SUCCESS = "[Suscripciones] add one success";
export const SUSCRIPTIONS_ADD_ONE_FAILURE = "[Suscripciones] add one failure";
export const SUSCRIPTIONS_UPDATE_ONE_SUBMMITING = "[Suscripciones] update one submmiting";
export const SUSCRIPTIONS_UPDATE_ONE_SUCCESS = "[Suscripciones] update one success";
export const SUSCRIPTIONS_UPDATE_ONE_FAILURE = "[Suscripciones] update one failure";
// Views
export const VIEWS_GET_SUBMITTING = "[Views] get submitting";
export const VIEWS_GET_FAILURE = "[Views] get failure";
export const VIEWS_GET_SUCCESS = "[Views] get success";
// Publicidad
export const ADVERTISING_GET_SUBMITING = "[Advertising] get submmiting";
export const ADVERTISING_GET_FAILURE = "[Advertising] get failure";
export const ADVERTISING_GET_SUCCESS = "[Advertising] get success";
export const ADVERTISING_SET_SELECTED = "[Advertising] set selected";
export const ADVERTISING_ADD_SUBMMITING = "[Advertising] add submmiting";
export const ADVERTISING_ADD_SUCCESS = "[Advertising] add success";
export const ADVERTISING_ADD_FAILURE = "[Advertising] add failure";
export const ADVERTISING_UPDATE_SUBMMITING = "[Advertising] update submmiting";
export const ADVERTISING_UPDATE_SUCCESS = "[Advertising] update success";
export const ADVERTISING_UPDATE_FAILURE = "[Advertising] update failure";
export const ADVERTISING_GET_ONE_SUBMITING = "[Advertising] get one submmiting";
export const ADVERTISING_GET_ONE_FAILURE = "[Advertising] get one failure";
export const ADVERTISING_GET_ONE_SUCCESS = "[Advertising] get one success";
export const ADVERTISING_LOCK_SUBMITTING = "[Advertising] lock submitting";
export const ADVERTISING_LOCK_SUCCESS = "[Advertising] lock success";
export const ADVERTISING_LOCK_FAILURE = "[Advertising] lock failure";
export const ADVERTISING_UNLOCK_SUBMITTING = "[Advertising] unlock submitting";
export const ADVERTISING_UNLOCK_SUCCESS = "[Advertising] unlock success";
export const ADVERTISING_UNLOCK_FAILURE = "[Advertising] unlock failure";
//Reportes
export const REPORTES_GET_SUBMITING = "[Reportes] get submmiting";
export const REPORTES_GET_FAILURE = "[Reportes] get failure";
export const REPORTES_GET_SUCCESS = "[Reportes] get success";