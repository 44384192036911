import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isAfter, isBefore } from "date-fns";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  AddAdvertising,
  addAdvertising,
  getAdvertisings,
} from "../redux/actions/advertising.Actions";
import { getViews } from "../redux/actions/views.Actions";
import { AdvertisingState } from "../redux/reducers/advertising.Reducer";
import { RootState } from "../redux/reducers/rootReducer";
import { ViewsState } from "../redux/reducers/views.Reducer";

interface Props {
  open: boolean;
  title: string;
  onClose: () => void;
  value?: any;
  error?: boolean;
  helperText?: string;
  valor1?: string;
  valor2?: string;
  valor3?: string;
}
const useStyles = makeStyles((theme) => ({
  ventana: {
    borderRadius: "6%",
    position: "absolute",
    width: "35vw",
    backgroundColor: "white",
    border: "2 px solid #f8f4f4",
    boxShadow: theme.shadows[3],
    padding: "16px 32px 24px",
    top: "5%",
    left: "30%",
    transform: "translate(-35% - 35%) ",
  },
  textField: {
    width: "100%",
  },
}));

export const ModalAdvertising: React.FC<Props> = ({ open, title, onClose }) => {
  const classes = useStyles();
  const [openDialog, setopenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleCloseDialog = () => {
    setopenDialog(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getViews());
    dispatch(getAdvertisings());
  }, []);

  const { views } = useSelector<RootState, ViewsState>(
    (state) => state.viewsReducer
  );

  const { advertisings } = useSelector<RootState, AdvertisingState>(
    (state) => state.advertisingReducer
  );

  const theme = useTheme();

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik<AddAdvertising>({
      initialValues: {
        Titulo: "",
        FechaInicio: "",
        FechaTermino: "",
        Vista: "",
        Posicion: "inferior",
        Link: "",
      },
      onSubmit: (values) => {
        const fechaInicioValues = new Date(values.FechaInicio);
        const fechaTerminoValues = new Date(values.FechaTermino);
        const todayDate = new Date();
        if (
          isBefore(fechaInicioValues, todayDate) &&
          isBefore(fechaTerminoValues, todayDate)
        ) {
          setErrorMsg(
            "Las fechas ingresadas deben ser igual a la fecha actual o posterior"
          );
          setopenDialog(true);
        } else {
          setErrorMsg(
            "No se ha podido crear la publicidad, debido a que ya existe una con el mismo rango de fecha, vista y posición."
          );
          if (
            advertisings.some((adv) => {
              const fechaInicioAdv = new Date(adv.FechaInicio);
              const fechaTerminoAdv = new Date(adv.FechaTermino);
              return (
                ((isAfter(fechaInicioValues, fechaInicioAdv) &&
                  isBefore(fechaInicioValues, fechaTerminoAdv)) ||
                  (isAfter(fechaTerminoValues, fechaInicioValues) &&
                    isBefore(fechaTerminoValues, fechaTerminoAdv))) &&
                adv.Vista === values.Vista &&
                adv.Posicion === values.Posicion
              );
            })
          ) {
            setopenDialog(true);
          } else {
            if(values.fileDesktop && values.fileMovil){
              dispatch(addAdvertising(values));
              onClose();
            }else{
              setErrorMsg("Debe ingresar un banner para vista Desktop y para vista Móvil.")
              setopenDialog(true)
            }
          }
        }
      },
      validationSchema: yup.object({
        Titulo: yup.string().required("Debe ingresar un titulo."),
        FechaInicio: yup
          .string()
          .required("Debe ingresar una fecha de inicio."),
        FechaTermino: yup
          .string()
          .required("Debe ingresar una fecha de termino."),
        Vista: yup.string().required("Debe seleccionar una vista."),
        Posicion: yup.string().required("Debe seleccionar una posición."),
        Link: yup.string().required("Debe indicar el enlace de referencia."),
      }),
    });

  const handleChangeImageDesktop = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && files.length === 1) {
      const file = files.item(0);
      if (file && file.size <= 2097152) {
        setFieldValue("fileDesktop", file);
      } else {
        // TODO: Debe saltar el dialogos
      }
    }
  };

  const handleChangeImageMovil = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && files.length === 1) {
      const file = files.item(0);
      if (file && file.size <= 2097152) {
        setFieldValue("fileMovil", file);
      } else {
        // TODO: Debe saltar el dialogos
      }
    }
  };

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <div className={classes.ventana}>
          <form onSubmit={handleSubmit}>
            <div style={{ alignItems: "Flex-start" }}>
              <h4>{title}</h4>
            </div>
            <TextField
              placeholder="Ingresa el nombre de la Campaña"
              type="text"
              label="Titulo"
              autoComplete="off"
              id="Titulo"
              name="Titulo"
              fullWidth={true}
              variant="outlined"
              value={values.Titulo}
              onChange={handleChange}
              error={touched.Titulo && Boolean(errors.Titulo)}
              helperText={touched.Titulo && errors.Titulo}
            />
            <Grid container spacing={2} style={{ marginTop: theme.spacing(6) }}>
              <Grid item xl={6}>
                <TextField
                  title="Fecha inicio"
                  label="Fecha Inicio"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  autoComplete="off"
                  id="FechaInicio"
                  name="FechaInicio"
                  fullWidth={true}
                  variant="outlined"
                  value={values.FechaInicio}
                  onChange={handleChange}
                  error={touched.FechaInicio && Boolean(errors.FechaInicio)}
                  helperText={touched.FechaInicio && errors.FechaInicio}
                />
              </Grid>
              <Grid item xl={6}>
                <TextField
                  type="date"
                  label="Fecha Termino"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                  id="FechaTermino"
                  name="FechaTermino"
                  fullWidth={true}
                  variant="outlined"
                  value={values.FechaTermino}
                  onChange={handleChange}
                  error={touched.FechaTermino && Boolean(errors.FechaTermino)}
                  helperText={touched.FechaTermino && errors.FechaTermino}
                />
              </Grid>
            </Grid>
            <Grid style={{ marginTop: theme.spacing(6) }}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="select-view">Vista</InputLabel>
                <Select
                  labelId="select-view"
                  id="Vista"
                  name="Vista"
                  placeholder="Selecciona vista"
                  value={values.Vista}
                  onChange={handleChange}
                >
                  {views.map((view, index) => (
                    <MenuItem key={`view-${index}`} value={view.Nombre}>
                      {view.Nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <TextField
              style={{ marginTop: theme.spacing(6) }}
              label="Link"
              placeholder="Ingresa el enlace de referencia"
              type="url"
              autoComplete="off"
              id="Link"
              name="Link"
              fullWidth={true}
              variant="outlined"
              value={values.Link}
              onChange={handleChange}
              error={touched.Link && Boolean(errors.Link)}
              helperText={touched.Link && errors.Link}
            />
            <TextField
              style={{ marginTop: theme.spacing(6) }}
              placeholder="Inserte banner para vista de escritorio"
              label="Banner Escritorio"
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              id="imagen"
              fullWidth={true}
              variant="outlined"
              onChange={handleChangeImageDesktop}
              error={touched.Link && Boolean(errors.Link)}
              helperText={touched.Link && errors.Link}
            />

            <TextField
              style={{ marginTop: theme.spacing(6) }}
              placeholder="Inserta banner para vista de móvil"
              label="Banner Móvil"
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              id="imagen"
              fullWidth={true}
              variant="outlined"
              onChange={handleChangeImageMovil}
              error={touched.Link && Boolean(errors.Link)}
              helperText={touched.Link && errors.Link}
            />

            <FormControl style={{ marginTop: theme.spacing(6) }}>
              <FormLabel>Posicion</FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  value={"superior"}
                  control={<Radio />}
                  label="Superior"
                  name="superior"
                  onChange={() => setFieldValue("Posicion", "superior")}
                ></FormControlLabel>
                <FormControlLabel
                  value="inferior"
                  control={<Radio />}
                  label="Inferior"
                  onChange={() => setFieldValue("Posicion", "inferior")}
                ></FormControlLabel>
              </RadioGroup>
            </FormControl>
            <Box display="flex" justifyContent="flex-end" paddingTop="2%">
              <Button
                type="submit"
                style={{ marginRight: 20 }}
                variant="contained"
                color="primary"
              >
                Guardar
              </Button>
              <Button variant="text" onClick={onClose}>
                Cancelar
              </Button>
            </Box>
          </form>
        </div>
      </Modal>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Error al crear publicidad"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
