import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Download, Eye, MinusSquare, Search } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { FormState } from "../models/form_state";
import {
  getMoreOffers,
  getOffers,
  getOffersFiltered,
  setSelectedOffer,
} from "../redux/actions/offers.Actions";
import { IOffers, offersReducer } from "../redux/reducers/offers.Reducer";
import { RootState } from "../redux/reducers/rootReducer";
import { useStyles } from "../theme/useStyles";
import {
  cleanString,
  exportToCsv,
  timesStampFormattedsimple,
} from "../utils/utils";
import RegionesComunas from "../constants/RegionesComunas.json";
import { getAllCategories } from "../redux/actions/category.Actions";
import { ICategory } from "../redux/reducers/category.Reducer";
import { Autocomplete } from "@material-ui/lab";

interface filterProps {
  changeOrder: (value: any) => void;
}

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const orderByUsers = ["Usuario", "Fecha", "Tipo usuario"];

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const activo = green[300];
  const bloqueado = red[300];

  const [subjectData, setSubjectData] = useState<any>(null);
  const [currentFilter, setCurrentFilter] = useState<any>({});
  const [sortBy, setSortBy] = useState("NombreCompleto");

  const handleChangeOrder = (order: any) => {
    dispatch(getOffers(limit, order));
  };
  const handleGoToViewOffers = (data: any) => {
    dispatch(setSelectedOffer(data));
    history.push(`/gestionOfertas/${data.id}/detalle`);
  };
  const { limit, page, handleLimitChange, handlePageChange } = useTable({
    limit: TABLE_LIMIT_DEFAULT || 5,
    onLimitChange: (e, newLimit) => {
      if (newLimit > offersReducer.length) {
        if (currentFilter) {
        }
        dispatch(getOffers(newLimit));
      }
    },
    onPageChange: (newPage, oldPage, limit) => {
      if (offers.length < totalDocs && newPage > oldPage) {
        dispatch(getMoreOffers(limit));
      }
    },
  });

  useEffect(() => {
    dispatch(getOffers());
  }, []);

  const { offers, state, totalDocs } = useSelector<RootState, IOffers>(
    (state) => state.offersReducer
  );
  const datosoferts = offers.map((e: any) => ({
    Nombre: e.Usuario.Nombre,
    Apellido: e.Usuario.Apellido,
    Bloqueado: e.Bloqueado,
    Descripcion: e.Descripcion,
    Deshabilitado: e.Deshabilitado,
    FechaCreacion: e.FechaCreacion,
    FechaInicio: e.FechaInicio,
    Mensaje: e.Mensaje,
    Titulo: e.Titulo,
  }));

  return (
    <>
      <CardHeader
        action={
          <Button
            startIcon={<Download />}
            style={{
              backgroundColor: "#007ac9",
              color: "#fff",
              marginInlineEnd: 20,
            }}
            variant="contained"
            onClick={() =>
              exportToCsv(
                "usuarios.csv",
                datosoferts,
                [
                  "Nombre",
                  "Apellido",
                  "Bloqueado",
                  "Descripcion",
                  "Deshabilitado",
                  "FechaCreacion",
                  "FechaInicio",
                  "Mensaje",
                  "Titulo",
                ],

                [
                  "Nombre",
                  "Apellido",
                  "Bloqueado",
                  "Descripcion",
                  "Deshabilitado",
                  "Fecha de Creacion",
                  "Fecha de Inicio",
                  "Mensaje",
                  "Titulo",
                ]
              )
            }
          >
            Exportar CSV
          </Button>
        }
      />
      <Card mb={6}>
        <FilterSection
          changeOrder={(order) => {
            setSortBy(order);
            handleChangeOrder(order);
          }}
        />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Nombre</TableCell>
                  <TableCell align="left">Apellido</TableCell>
                  <TableCell align="left">Region</TableCell>
                  <TableCell align="left">Monto</TableCell>
                  <TableCell align="left">Fecha de Creación</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state === FormState.Submitting ||
                  state === FormState.Initial) && (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
                {state === FormState.Success &&
                  offers
                    .slice(page * limit, page * limit + limit)
                    .map((data: any) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          <TableCell align="left">
                            {data?.Usuario?.Nombre}
                          </TableCell>
                          <TableCell align="left">
                            {data?.Usuario?.Apellido}
                          </TableCell>
                          <TableCell align="left">
                            {data?.Usuario?.Region}
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{data?.Precio}$CLP</Typography>
                          </TableCell>

                          <TableCell align="left">
                            {timesStampFormattedsimple(
                              data?.FechaCreacion.toDate()
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Box style={{ justifyContent: "flex-start" }}>
                              <Tooltip title="Ver">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    handleGoToViewOffers(data);
                                  }}
                                >
                                  <Eye />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
      </Card>
    </>
  );
};
const FilterSection: React.FC<filterProps> = ({ changeOrder }) => {
  const dispatch = useDispatch();

  const [openFilter, setOpenFilters] = useState(false);
  const [filtersList, setValueFiltersList] = React.useState<any>({});
  const [valueCreacionStart, setValueCreacionStart] = useState<any>(null);
  const [valueCreacionEnd, setValueCreacionEnd] = useState<any>(null);
  const [valueNombre, setValueNombre] = useState("");
  const [valueApellido, setValueApellido] = useState("");
  const [valueComuna, setValueComuna] = useState<any>("");
  const [valueCategoria, setValueCategoria] = useState<any>("");

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  const { categorias } = useSelector<RootState, ICategory>(
    (state) => state.categoryReducer
  );

  const handleChangeNombre = (e: any) => {
    setValueNombre(e.target.value);
  };
  const handleChangeApellido = (e: any) => {
    setValueApellido(e.target.value);
  };

  const comunas = RegionesComunas.regiones
    .map((region) => {
      return region.comunas.map((comuna, index) => {
        return comuna;
      });
    })
    .flat();

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <InputLabel id="demo-select-small">Comuna</InputLabel>
            <Autocomplete
              options={comunas}
              value={valueComuna}
              onChange={(event, newValue) => {
                setValueComuna(newValue);
                filtersList["Comuna"] = newValue;
                console.log(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            ></Autocomplete>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="demo-select-small">Categoria</InputLabel>
            <Autocomplete
              options={categorias.map((categoria: any) => {
                return categoria.Nombre;
              })}
              value={valueCategoria}
              onChange={(event, newValue) => {
                setValueCategoria(newValue);
                filtersList["Categoria"] = newValue;
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            ></Autocomplete>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="creacion-label">Fecha Creación</InputLabel>
            <TextField
              id="creacion-start"
              type="datetime-local"
              value={valueCreacionStart}
              onChange={(event) => {
                setValueCreacionStart(event.target.value);
                filtersList["startAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={3} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="medium"
                    aria-label="Filtrar"
                    onClick={() => {
                      // if (valueCreacionStart && valueCreacionEnd) {
                      dispatch(getOffersFiltered(filtersList));
                      // }
                    }}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={3} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="medium"
                    aria-label="Borrar filtro"
                    onClick={() => {
                      //setValueNombre("");
                      //setValueApellido("");
                      setValueCreacionStart("");
                      //setValueCreacionEnd("");
                      setValueCategoria("");
                      setValueComuna("");
                      setValueFiltersList({});
                      dispatch(getOffers());
                    }}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};
export const Offers = () => {
  return (
    <React.Fragment>
      <Helmet title="Ofertas" />
      <Typography variant="h3" gutterBottom display="inline">
        Ofertas
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/ofertas">
          Ofertas
        </Link>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
