import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { ImageRounded } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import RoomIcon from "@material-ui/icons/Room";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import {
  bloquedSolicitud,
  getOneSolicitud,
  getReportes,
  unlockedSolicitud,
} from "../redux/actions/solicitudesActions";
import { RootState } from "../redux/reducers/rootReducer";
import { ISolicitudes } from "../redux/reducers/solicitudesReducer";
import sinImagen from "../vendor/sin_imagen.jpg";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = () => {
  const dispatch = useDispatch();
  const { solicitudId } = useParams<any>();
  const [openBlocked, setOpenBlocked] = useState(false);
  const [openUnbloqued, setOpenUnblocked] = useState(false);
  const activo = green[300];
  const bloqueado = red[300];
  const Estado = "Bloqueado";
  const history = useHistory();
  let image =
    " https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg";

  const handleUnbloquedUser = (user: any) => {
    setOpenBlocked(false);
    alert("Bloqueando usuario");
  };
  const handleBloquedUser = (user: any) => {
    setOpenBlocked(false);
    alert("Bloqueando usuario");
  };

  const { selectedSolicitud, state } = useSelector<RootState, ISolicitudes>(
    (state) => state.solicitudesReducer
  );
  const { reportes } = useSelector<RootState, ISolicitudes>(
    (state) => state.solicitudesReducer
  );



  console.log(selectedSolicitud?.Imagenes?.map((imagen: any) => imagen.URL));
  useEffect(() => {
    if (!selectedSolicitud) {
      dispatch(getOneSolicitud(solicitudId));
    }
    
  }, [selectedSolicitud, solicitudId]);

  useEffect(() => {
   dispatch(getReportes(solicitudId))
  }, [])

  const CountReport = (motivo:string) =>{


const prueba = reportes.filter((x:any) => x.Reporte ===motivo).length
return prueba




  }


  return (
    <>
      <CardHeader
        action={
          <Box display="flex">
            {selectedSolicitud?.Bloqueado === false ? (
              <Button
                variant="contained"
                style={{ backgroundColor: bloqueado, marginRight: 5 }}
                onClick={() => {
                  setOpenBlocked(true);
                }}
              >
                {" "}
                Bloquear Solicitud
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ backgroundColor: activo }}
                onClick={() => {
                  setOpenUnblocked(true);
                }}
              >
                {" "}
                Desbloquear Solicitud
              </Button>
            )}
          </Box>
        }
      />
      <Card mb={2}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h4"
                style={{ alignItems: "center", color: "#009fda" }}
              >
                {selectedSolicitud?.Titulo}
              </Typography>
              <Typography
                style={{ color: "#a4a7a9", fontWeight: 600, marginTop: 10 }}
              >
                Descripción del trabajo:
                <br />
                {selectedSolicitud?.Descripcion}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Carousel
                  itemsToShow={1}
                  isRTL
                  transitionMs={800}
                  initialFirstItem={3}
                >
                  {selectedSolicitud?.Imagenes ? (
                    selectedSolicitud?.Imagenes?.map((imagen: any) => (
                      <>
                        <Box key={imagen.id} style={{ alignItems: "center" }}>
                          <img
                            style={{
                              padding: 0,
                              margin: 0,
                              width: 300,
                              height: 250,
                            }}
                            height="280"
                            src={imagen.URL}
                          />
                        </Box>
                      </>
                    ))
                  ) : (
                    <Box style={{ marginLeft: 20, alignItems: "center" }}>
                      <img
                        style={{ borderRadius: 6, width: 300, height: 250 }}
                        height="280"
                        src={sinImagen}
                      />
                    </Box>
                  )}
                </Carousel>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box alignItems="center" justifyContent="center">
                <Box display="flex">
                  <Box style={{ color: "#009fda" }}>
                    <LocalOfferIcon style={{ marginRight: 4 }} />
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        color: "#009fda",
                        fontWeight: 600,
                        fontSize: 15,
                      }}
                    >
                      {selectedSolicitud?.Categoria}
                    </Typography>
                  </Box>
                </Box>
                <br />
                <Box display="flex">
                  <Box style={{ color: "#009fda" }}>
                    <RoomIcon style={{ marginRight: 4 }} />
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        color: "#009fda",
                        fontWeight: 600,
                        fontSize: 15,
                      }}
                    >
                      {" "}
                      Ubicacion:
                    </Typography>
                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                      {selectedSolicitud?.Direccion}
                    </Typography>
                  </Box>
                </Box>
                <br />
                <Box display="flex">
                  <Box style={{ color: "#009fda" }}>
                    <DateRangeIcon style={{ marginRight: 4 }} />
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        color: "#009fda",
                        fontWeight: 600,
                        fontSize: 15,
                      }}
                    >
                      Fecha De inicio y Termino
                    </Typography>
                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                      Inicia: {selectedSolicitud?.FechaInicio}
                    </Typography>
                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                      Finaliza: {selectedSolicitud?.FechaTermino}
                    </Typography>
                  </Box>
                </Box>

                <br />
                <Box display="flex">
                  <Box style={{ color: "#009fda" }}>
                    <MonetizationOnIcon style={{ marginRight: 4 }} />
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        color: "#009fda",
                        fontWeight: 600,
                        fontSize: 15,
                      }}
                    >
                      Presupuesto
                    </Typography>
                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                      {selectedSolicitud?.Precio} $ CLP
                    </Typography>
                  </Box>
                </Box>
                


                <br />
                <Box display="flex">
                  {/* <Box style={{ color: "#009fda" }}>
                    <DateRangeIcon style={{ marginRight: 4 }} />
                  </Box> */}
                  <Box>
                    <Typography
                      style={{
                        color: "#009fda",
                        fontWeight: 600,
                        fontSize: 15,
                      }}
                    >
                      Reportes
                    </Typography>
                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                      Total de reportes: {selectedSolicitud?.CantidadReportes}
                    </Typography>
                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                    Desnudos: {CountReport("Desnudos")}
                     </Typography>

                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                      Violencia: {CountReport("Violencia")}
                    </Typography>

                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                    Informacion Falsa:{CountReport("Informacion Falsa")}
                    </Typography>

                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                    Lenguaje Ofensivo: {CountReport("Lenguaje Ofensivo")}
                    </Typography>

                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                    Venta no autorizada: {CountReport("Venta no autorizada")}
                    </Typography>

                    <Typography style={{ color: "#a4a7a9", fontWeight: 600 }}>
                    Otros: {CountReport("Otros")}
                    </Typography>
                    
                  </Box>
                </Box>



              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                style={{ marginLeft: 20 }}
              >
                <img
                  style={{ borderRadius: 10, width: 400, height: 200 }}
                  src={
                    "https://maps.googleapis.com/maps/api/staticmap?" +
                    "key=AIzaSyD9HmNtNbieIc0zsZLjErteatS3R65HYJg&size=500x300&style=feature:poi|visibility:off" +
                    `&markers=anchor:bottomleft|icon:https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png%7Cshadow:true%7C${selectedSolicitud?.Ubicacion?.lat},${selectedSolicitud?.Ubicacion?.lng}`
                  }
                />
                <Typography style={{ color: "#a4a7a9" }}>
                  (Ubicacion Aproximada)
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <NewConfirmDialog
            open={openBlocked}
            onClose={() => setOpenBlocked(false)}
            title="¿Desea Bloquear esta solicitud?"
            onConfirm={() => {
              dispatch(bloquedSolicitud(solicitudId));
              history.push("/solicitudes");
            }}
            onConfirmText={"Bloquear"}
          />
          <NewConfirmDialog
            open={openUnbloqued}
            onClose={() => setOpenUnblocked(false)}
            title="¿Desea Desbloquear esta soliciud?"
            onConfirm={() => {
              dispatch(unlockedSolicitud(solicitudId));
              history.push("/solicitudes");
            }}
            onConfirmText={"Desbloquear"}
          />
        </CardContent>
      </Card>
    </>
  );
};

export const SolicitudesView = () => {
  return (
    <React.Fragment>
      <Helmet title="Solicitudes" />
      <Typography variant="h3" gutterBottom display="inline">
        Ver Solicitud
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/solicitudes">
          Listado
        </Link>
        <Typography>Detalle de solicitud</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
