import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface IOffers {
  offers: any[];
  state: FormState;
  error?: string;
  totalDocs: any;
  lastDoc?: any;
  selectedOffers?: any;
  delete?: {
    state: FormState;
    error?: string;
  };
  edit?: {
    state: FormState;
    error?: string;
  };
  update?: {
    state: FormState;
    error?: string;
  };
}

const initialState = {
  offers: [],
  state: FormState.Initial,
  totalDocs: 0,
  selectedOffers: null,
  delete: {
    state: FormState.Initial,
  },
  edit: {
    state: FormState.Initial,
  },
} as IOffers;

export const offersReducer = (
  state = initialState,
  action: Action
): IOffers => {
  switch (action.type) {
    case types.OFFERS_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        offers: action.payload.offers,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.OFFERS_GET_SUBMMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.OFFERS_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };

    case types.OFFERS_SET_SELECTED:
      return {
        ...state,
        selectedOffers: action.payload,
      };
    case types.OFFERS_GET_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.OFFERS_GET_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
        selectedOffers: action.payload,
      };
    case types.OFFERS_GET_ONE_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: action.payload,
        },
      };
      case types.OFFERS_BLOQUED_SUBMMITING:
        return {
          ...state,
          delete: {
            state: FormState.Submitting,
          },
        };
      case types.OFFERS_BLOQUED_FAILURE:
        return {
          ...state,
          delete: {
            state: FormState.Failure,
            error: action.payload,
          },
        };
        case types.OFFERS_BLOQUED_SUCCESS:
          return {
            ...state,
            delete: {
              state: FormState.Success,
            },
          };
          //
          case types.OFFERS_UNLOCKED_SUBMMITING:
        return {
          ...state,
          delete: {
            state: FormState.Submitting,
          },
        };
      case types.OFFERS_UNLOCKED_FAILURE:
        return {
          ...state,
          delete: {
            state: FormState.Failure,
            error: action.payload,
          },
        };
        case types.OFFERS_UNLOCKED_SUCCESS:
          return {
            ...state,
            delete: {
              state: FormState.Success,
            },
          };
    default:
      return state;
  }
};
