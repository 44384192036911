import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Collapse,
  Divider as MuiDivider,
  Fade,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { Download, Eye, MinusSquare, Search } from "react-feather";
import { Helmet } from "react-helmet-async";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { TableSkeleton } from "../components/TableSkeleton";
import { FormState } from "../models/form_state";
import {
  cleanString,
  exportToCsv,
  timesStampFormattedsimple,
} from "../utils/utils";
import AddIcon from "@material-ui/icons/Add";
import { RootState } from "../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  ISuscription,
  suscriptionReducer,
} from "../redux/reducers/suscriptions.Reducer";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import {
  getSuscriptions,
  setSelectedSuscription,
  addSuscription,
  updateSuscription,
  getSuscripcionFiltered,
} from "../redux/actions/suscription.Actions";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { getMoreCategories } from "../redux/actions/category.Actions";
import { ModalSuscripcion } from "../components/ModalSuscripcion";
import { ModalSuscripcionEdit } from "../components/ModalSuscripcionEdit";
import {
  Delete,
  ExpandLess,
  ExpandMore,
  NotInterested,
} from "@material-ui/icons";
import { getOffers } from "../redux/actions/offers.Actions";
import { ModalAdvertising } from "../components/ModalAdvertising";
import {
  getAdvertisings,
  setSelectedAdvertising,
} from "../redux/actions/advertising.Actions";
import { AdvertisingState } from "../redux/reducers/advertising.Reducer";
import { Advertising as AdvertisingType } from "../models/Advertising";
import { ModalAdvertisingEdit } from "../components/ModalAdvertisingEdit";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
interface filterProps {
  changeOrder: (value: any) => void;
}

const ContentCard = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentFilter, setCurrentFilter] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [sortBy, setSortBy] = useState("NombreCompleto");

  const handleChangeOrder = (order: any) => {
    dispatch(getOffers(limit, order));
  };

  const openCloseModal = () => {
    setOpenModal(!openModal);
  };

  const openEditModal = () => {
    setOpenModalEdit(true);
  };
  const openCloseModalEdit = () => {
    setOpenModalEdit(false);
  };
  const handleAccepEdit = (data: AdvertisingType) => {
    dispatch(setSelectedAdvertising(data));
    openEditModal();
  };

  const handleGoToViewAdvertising = (data: any) => {
    dispatch(setSelectedAdvertising(data));
    history.push(`/detalleCampaña/${data.id}/detalle`);
  };

  const { limit, page, handleLimitChange, handlePageChange } = useTable({
    limit: TABLE_LIMIT_DEFAULT || 5,
    onLimitChange: (e, newLimit) => {
      if (newLimit > suscriptionReducer.length) {
        if (currentFilter) {
        }
      }
    },
    onPageChange: (newPage, oldPage, limit) => {
      if (advertisings.length < totalDocs && newPage > oldPage) {
        dispatch(getMoreCategories(limit));
      }
    },
  });

  useEffect(() => {
    dispatch(getAdvertisings());
  }, []);

  const { advertisings, state, totalDocs, selectedAdvertising } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);

  const dataAdvertising = advertisings.map((e: any) => ({
    Titulo: e.Titulo,
    FechaInicio: e.FechaInicio,
    FechaTermino: e.FechaTermino,
    Vista: e.Vista,
    Posicion: e.Posicion,
    Link: e.Link,
    Activo: e.Activo === true ? "Activo" : "Desactivado",
    FechaCreacion: e.FechaCreacion,
  }));

  return (
    <>
      <CardHeader
        action={
          <>
            <Button
              startIcon={<AddIcon />}
              style={{
                backgroundColor: "#007ac9",
                color: "#fff",
                marginInlineEnd: 20,
                marginLeft: 10,
              }}
              onClick={() => {
                openCloseModal();
              }}
            >
              Agregar Campaña
            </Button>
            <Button
              startIcon={<Download />}
              style={{
                backgroundColor: "#007ac9",
                color: "#fff",
                marginInlineEnd: 20,
              }}
              variant="contained"
              onClick={() =>
                exportToCsv(
                  "publicidades.csv",
                  dataAdvertising,
                  [
                    "Titulo",
                    "FechaInicio",
                    "FechaTermino",
                    "Vista",
                    "Posicion",
                    "Link",
                    "Activo",
                    "FechaCreacion",
                  ],

                  [
                    "Titulo",
                    "FechaInicio",
                    "FechaTermino",
                    "Vista",
                    "Posicion",
                    "Link",
                    "Activo",
                    "FechaCreacion",
                  ]
                )
              }
            >
              Exportar CSV
            </Button>
          </>
        }
      />
      <Card mb={6}>
        <FilterSection
          changeOrder={(order) => {
            setSortBy(order);
            handleChangeOrder(order);
          }}
        />
        <CardContent>
          {/* Tabla de Contenido */}
          <TableContainer>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Titulo Campaña</TableCell>
                  <TableCell align="left">Fecha Inicio</TableCell>
                  <TableCell align="left">Fecha Termino</TableCell>
                  <TableCell align="left">Vista</TableCell>
                  <TableCell align="left">Posicion</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state === FormState.Submitting ||
                  state === FormState.Initial) && (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
                {state === FormState.Success &&
                  advertisings
                    .slice(page * limit, page * limit + limit)
                    .map((data: any) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover>
                          {data.Activo === true ? (
                            <TableCell align="left">{data?.Titulo}</TableCell>
                          ) : (
                            <TableCell
                              align="left"
                              style={{ color: "#c62828", fontWeight: "bold" }}
                            >
                              {data?.Titulo}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            {data?.FechaInicio}
                          </TableCell>
                          <TableCell align="left">
                            <Typography> {data?.FechaTermino}</Typography>
                          </TableCell>
                          <TableCell align="left">{data?.Vista}</TableCell>
                          <TableCell align="left">{data?.Posicion}</TableCell>
                          <TableCell align="center">
                            <Box style={{ justifyContent: "flex-start" }}>
                              <Tooltip title="Editar">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleAccepEdit(data)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Ver">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    handleGoToViewAdvertising(data);
                                  }}
                                >
                                  <Eye />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
          <ModalAdvertising
            title="Agregar Campaña"
            open={openModal}
            onClose={openCloseModal}
          />
          <ModalAdvertisingEdit
            selected={selectedAdvertising}
            title="Editar Campaña"
            open={openModalEdit}
            onClose={openCloseModalEdit}
          />
        </CardContent>
      </Card>
    </>
  );
});
const FilterSection: React.FC<filterProps> = ({ changeOrder }) => {
  const dispatch = useDispatch();

  const [openFilter, setOpenFilters] = useState(false);
  const [filtersList, setValueFiltersList] = React.useState<any>({});
  const [valueCreacionStart, setValueCreacionStart] = useState<any>(null);
  const [valueCreacionEnd, setValueCreacionEnd] = useState<any>(null);
  const [valueNombre, setValueNombre] = useState("");
  const [valueApellido, setValueApellido] = useState("");
  const [valueCargo, setValueCargo] = useState<any>(null);

  const handleChangeNombre = (e: any) => {
    setValueNombre(e.target.value);
  };

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="outlined-basic"
              size="small"
              label="Nombre Suscripcion"
              variant="outlined"
              fullWidth
              value={valueNombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["NombreSuscripcion_lower"] =
                    cleanString(valueNombre);

                  if (!valueNombre) {
                    delete filtersList["NombreSuscripcion_lower"];
                  }

                  if (Object.keys(filtersList).length > 0) {
                    dispatch(getSuscripcionFiltered(filtersList));
                  } else {
                    dispatch(getSuscriptions());
                  }

                  ev.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Inicio"
              size="small"
              type="datetime-local"
              value={valueCreacionStart}
              onChange={(event) => {
                setValueCreacionStart(event.target.value);
                filtersList["endAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={valueCreacionEnd}
              onChange={(event) => {
                setValueCreacionEnd(event.target.value);
                filtersList["startAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={1} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    aria-label="Filtrar"
                    onClick={() => {
                      if (valueNombre) {
                        filtersList["NombreSuscripcion_lower"] =
                          cleanString(valueNombre);
                      }
                      // if (valueCreacionStart && valueCreacionEnd) {
                      dispatch(getSuscripcionFiltered(filtersList));
                      // }
                    }}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="small"
                    aria-label="Borrar filtro"
                    onClick={() => {
                      setValueNombre("");
                      setValueCreacionStart("");
                      setValueCreacionEnd("");
                      setValueFiltersList({});
                      dispatch(getSuscriptions());
                    }}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};
export const AdvertisingCampaign = () => (
  <React.Fragment>
    <Helmet title="Campañas Publicitarias" />
    <Typography variant="h3" gutterBottom display="inline">
      Campañas Publicitarias
    </Typography>

    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Campañas Publicitarias</Typography>
    </Breadcrumbs>

    <Divider my={6} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ContentCard />
      </Grid>
    </Grid>
  </React.Fragment>
);
