import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { openSnack } from "./uiActions";

export const getSolicitudes = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.SOLICITUDES_GET_SUBMMITING,
    });
    try {
      const response = await firestore
        .collection("Solicitudes")
        .limit(limit)
        .orderBy(order, "desc")
        .get();
      const responseTotal = await firestore.collection("Solicitudes").get();
      const responseSolicitud = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.SOLICITUDES_GET_SUCCESS,
        payload: {
          solicitudes: responseSolicitud,
          totalDocs: responseTotal.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.SOLICITUDES_GET_FAILURE,
        payload: "Ha ocurrido un error al cargar las Solicitudes",
      });
    }
  };
};

export const getOneSolicitud = (id: any): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.SOLICITUDES_GET_ONE_SUBMMITING,
    });
    try {
      const responseSolicitud = await firestore
        .collection("Solicitudes")
        .doc(id)
        .get();

      dispatch({
        type: types.SOLICITUDES_GET_ONE_SUCCESS,
        payload: responseSolicitud.data(),
      });
    } catch (error: any) {
      dispatch({
        type: types.SOLICITUDES_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error al Obtener la Solicitud",
      });
    }
  };
};

export const bloquedSolicitud = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.SOLICITUDES_BLOQUED_SUBMMITING,
    });
    try {
      const responseSolicitud = await firestore
        .collection("Solicitudes")
        .doc(id)
        .update({
          Bloqueado: true,
        });
      dispatch(
        openSnack(
          "Se ha Bloqueado la solicitud exitosamente",
          SnackState.SUCCESS
        )
      );
    } catch (error: any) {
      dispatch({
        type: types.SOLICITUDES_BLOQUED_FAILURE,
        payload: "Ha ocurrido un error al bloquear la Solicitud",
      });
    }
  };
};
export const unlockedSolicitud = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.SOLICITUDES_UNLOCKED_SUBMMITING,
    });
    try {
      const responseSolicitud = await firestore
        .collection("Solicitudes")
        .doc(id)
        .update({
          Bloqueado: false,
        });
      dispatch(
        openSnack(
          "Se ha Desbloqueado la solicitud exitosamente",
          SnackState.SUCCESS
        )
      );
    } catch (error: any) {
      dispatch({
        type: types.SOLICITUDES_UNLOCKED_FAILURE,
        payload: "Ha ocurrido un error al desbloquear la Solicitud",
      });
    }
  };
};

export const getSolicitudFiltered = (
  filtro: any = {},
  order?: string,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      let query: any;

      if (order) {
        query = firestore.collection("Solicitudes").orderBy("FechaCreacion");
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else {
            query = query.where(key, "==", value);
          }
        });
        const response = await query.get();
        const solicitudList = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.SOLICITUDES_GET_SUCCESS,
          payload: {
            solicitudes: solicitudList,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      } else {
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];
          if (key === "startAt") {
            query = firestore
              .collection("Solicitudes")
              .orderBy("FechaCreacion");
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "endAt") {
            query = firestore
              .collection("Solicitudes")
              .orderBy("FechaCreacion");
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else if (key === "Destacado") {
            query = firestore.collection("Solicitudes").orderBy("FechaPremium");
            if (value === "Si") {
              const todayDate = new Date();
              query = query.startAfter(todayDate);
            } else {
              const todayDate = new Date();
              query = query.endAt(todayDate);
            }
          }else if (key === "Estado") {
            query = firestore
              .collection("Solicitudes")
              .orderBy("FechaCreacion");
            query = query.where(value, "==", true);
          }else {
            query = firestore
              .collection("Solicitudes")
              .orderBy("FechaCreacion");
            query = query.where(key, "==", value);
          }
        });
        const response = await query.get();
        const solicitudList = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        console.log(solicitudList)
        dispatch({
          type: types.SOLICITUDES_GET_SUCCESS,
          payload: {
            solicitudes: solicitudList,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};
export const getMoreSolicitudes = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.SOLICITUDES_GET_SUBMMITING,
    });

    const lastDoc = getState().solicitudesReducer.lastDoc || "";
    const { totalDocs, solicitudes } = getState().solicitudesReducer;
    try {
      const response = await firestore
        .collection("Solicitudes")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const respuestaSolicitudes = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.SOLICITUDES_GET_SUCCESS,
        payload: {
          solicitudes: solicitudes.concat(respuestaSolicitudes),
          totalDocs: totalDocs,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.SOLICITUDES_GET_FAILURE,
        payload: "Ha ocurrido un error al cargas mas solicitudes",
      });
    }
  };
};

export const setSelectedSolicitud = (solicitud: any): Action => ({
  type: types.SOLICITUDES_SET_SELECTED,
  payload: solicitud,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.SOLICITUDES_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.SOLICITUDES_FAILURE,
  payload: error,
});




export const getReportes = (id: any): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.REPORTES_GET_SUBMITING,
    });
    try {

      console.log("Get Reportes");

      const response = await firestore
      .collection("Reportes")
      .where("idSolicitud", "==", id)
      .get();

    const respuestaReportes = response.docs.map((x) => ({
      ...x.data(),
      id: x.id,
      datos: x.data()
    }));

    console.log(respuestaReportes);


       

      dispatch({
        type: types.REPORTES_GET_SUCCESS,
        payload: respuestaReportes,
      }); 
    } catch (error: any) {
      dispatch({
        type: types.REPORTES_GET_FAILURE,
        payload: "Ha ocurrido un error al Obtener la Solicitud",
      });
    }
  };
};