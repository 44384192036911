import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Collapse,
  Divider as MuiDivider,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { isBefore } from "date-fns";
import React, { useEffect, useState } from "react";
import { Download, Eye, MinusSquare, Search } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { TableSkeleton } from "../components/TableSkeleton";
import { TABLE_LIMITS, TABLE_LIMIT_DEFAULT } from "../constants";
import { useTable } from "../hooks/useTable";
import { FormState } from "../models/form_state";
import {
  getMoreSolicitudes,
  getSolicitudes,
  getSolicitudFiltered,
  setSelectedSolicitud,
} from "../redux/actions/solicitudesActions";
import { RootState } from "../redux/reducers/rootReducer";
import {
  ISolicitudes,
  solicitudesReducer,
} from "../redux/reducers/solicitudesReducer";
import { useStyles } from "../theme/useStyles";
import RegionesComunas from "../constants/RegionesComunas.json";
import {
  cleanString,
  exportToCsv,
  timesStampFormattedsimple,
} from "../utils/utils";
import { getAllCategories } from "../redux/actions/category.Actions";
import { ICategory } from "../redux/reducers/category.Reducer";
import { Autocomplete } from "@material-ui/lab";

interface filterProps {
  changeOrder: (value: any) => void;
}

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const orderByUsers = ["Usuario", "Fecha", "Tipo usuario"];

const ContentCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const activo = green[300];
  const bloqueado = red[300];

  const [subjectData, setSubjectData] = useState<any>(null);
  const [currentFilter, setCurrentFilter] = useState<any>({});
  const [sortBy, setSortBy] = useState("NombreCompleto");

  const handleChangeOrder = (order: any) => {
    dispatch(getSolicitudes(limit, order));
  };

  const { limit, page, handleLimitChange, handlePageChange } = useTable({
    limit: TABLE_LIMIT_DEFAULT || 5,
    onLimitChange: (e, newLimit) => {
      if (newLimit > solicitudesReducer.length) {
        if (currentFilter) {
        }
      }
    },
    onPageChange: (newPage, oldPage, limit) => {
      if (solicitudes.length < totalDocs && newPage > oldPage) {
        dispatch(getMoreSolicitudes(limit));
      }
    },
  });

  const handleGoToViewSolicitud = (data: any) => {
    dispatch(setSelectedSolicitud(data));
    history.push(`/gestionSolicutudes/${data.id}/detalle`);
  };
  useEffect(() => {
    dispatch(getSolicitudes());
  }, []);

  const { solicitudes, state, totalDocs } = useSelector<
    RootState,
    ISolicitudes
  >((state) => state.solicitudesReducer);

  console.log(solicitudes);
  return (
    <>
      <CardHeader
        action={
          <Button
            startIcon={<Download />}
            style={{
              backgroundColor: "#007ac9",
              color: "#fff",
              marginInlineEnd: 20,
            }}
            variant="contained"
            onClick={() =>
              exportToCsv(
                "solicitudes.csv",
                solicitudes.map((solicitud) => {
                  /* const soliCopy = {...solicitud}
                  soliCopy. */
                  return {
                    ...solicitud,
                    NombreCompleto: `${solicitud.Usuario.Nombre} ${solicitud.Usuario.Apellido}`,
                    TipoUser: "Demandante",
                    Destacado:
                      solicitud.FechaPremium === null
                        ? "No se ha destacado"
                        : solicitud.FechaPremium,
                    CantidadReportes: solicitud.CantidadReportes ? solicitud.CantidadReportes : "0",
                  };
                }),
                [
                  "NombreCompleto",
                  "Categoria",
                  "Titulo",
                  "Descripcion",
                  "Unidad.Tipo",
                  "TipoUser",
                  "Direccion",
                  "Precio",
                  "FechaCreacion",
                  "FechaInicio",
                  "FechaTermino",
                  "Destacado",
                  "CantidadReportes",
                ],
                [
                  "Nombre Completo",
                  "Categoria",
                  "Titulo",
                  "Descripcion",
                  "Unidad",
                  "Tipo de usuario",
                  "Direccion",
                  "Presupuesto",
                  "Fecha de solicitud",
                  "Fecha de Inicio",
                  "Fecha de Termino",
                  "Fecha Premium",
                  "Cantidad de Reportes",
                ]
              )
            }
          >
            Exportar CSV
          </Button>
        }
      />
      <Card mb={6}>
        <FilterSection
          changeOrder={(order) => {
            setSortBy(order);
            handleChangeOrder(order);
          }}
        />
        <CardContent>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Nombre</TableCell>
                  <TableCell align="left">Apellido</TableCell>
                  <TableCell align="left">Titulo</TableCell>
                  <TableCell align="left">Comuna</TableCell>
                  <TableCell align="left">Fecha de Creación</TableCell>
                  <TableCell align="left">Destacado</TableCell>
                  <TableCell align="left">Cantidad Reportes</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state === FormState.Submitting ||
                  state === FormState.Initial) && (
                  <TableSkeleton colSpan={10} limit={10} />
                )}
                {state === FormState.Success &&
                  solicitudes
                    .slice(page * limit, page * limit + limit)
                    .map((data: any) => (
                      <Fade key={data.id} in={true}>
                        <TableRow hover className={classes.styledRow}>
                          {data?.Bloqueado === false ? (
                            <TableCell align="left">
                              {data?.Usuario?.Nombre}
                            </TableCell>
                          ) : (
                            <TableCell
                              align="left"
                              style={{ color: "#c62828", fontWeight: "bold" }}
                            >
                              {data?.Usuario?.Nombre}
                            </TableCell>
                          )}
                          {data?.Bloqueado === false ? (
                            <TableCell align="left">
                              {data?.Usuario?.Apellido}
                            </TableCell>
                          ) : (
                            <TableCell
                              align="left"
                              style={{ color: "#c62828", fontWeight: "bold" }}
                            >
                              {data?.Usuario?.Apellido}
                            </TableCell>
                          )}
                          {data?.Bloqueado === false ? (
                            <TableCell align="left">{data?.Titulo}</TableCell>
                          ) : (
                            <TableCell
                              align="left"
                              style={{ color: "#c62828", fontWeight: "bold" }}
                            >
                              {data?.Titulo}
                            </TableCell>
                          )}

                          <TableCell align="left">{data?.Comuna}</TableCell>

                          <TableCell align="left">
                            {timesStampFormattedsimple(
                              data?.FechaCreacion.toDate()
                            )}
                          </TableCell>

                          {data.FechaPremium === null ||
                          isBefore(data?.FechaPremium.toDate(), new Date()) ===
                            true ? (
                            <TableCell align="center">No</TableCell>
                          ) : (
                            <TableCell align="center">Si</TableCell>
                          )}
                          <TableCell align="center">
                            {data?.CantidadReportes ? data.CantidadReportes : 0}
                          </TableCell>
                          <TableCell align="center">
                            <Box style={{ justifyContent: "flex-start" }}>
                              <Tooltip title="Ver">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    handleGoToViewSolicitud(data);
                                  }}
                                >
                                  <Eye />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalDocs}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_LIMITS}
          />
        </CardContent>
      </Card>
    </>
  );
};
const FilterSection: React.FC<filterProps> = ({ changeOrder }) => {
  const dispatch = useDispatch();

  const [openFilter, setOpenFilters] = useState(false);
  const [filtersList, setValueFiltersList] = React.useState<any>({});
  const [valueCreacionStart, setValueCreacionStart] = useState<any>(null);
  //const [valueCreacionEnd, setValueCreacionEnd] = useState<any>(null);
  //const [valueNombre, setValueNombre] = useState("");
  const [valueComuna, setvalueComuna] = useState<any>("");
  //const [valueApellido, setValueApellido] = useState("");
  const [valueDestacado, setvalueDestacado] = useState<any>("");
  const [valueCategoria, setValueCategoria] = useState<any>("");
  const [valueEstado, setValueEstado] = useState<any>("");

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  const { categorias } = useSelector<RootState, ICategory>(
    (state) => state.categoryReducer
  );

  /* const handleChangeNombre = (e: any) => {
    setValueNombre(e.target.value);
  };
  const handleChangeApellido = (e: any) => {
    setValueApellido(e.target.value);
  }; */

  const handleChangeDestacado = (e: any) => {
    setvalueDestacado(e.target.value);
  };

  const comunas = RegionesComunas.regiones
    .map((region) => {
      return region.comunas.map((comuna, index) => {
        return comuna;
      });
    })
    .flat();

  return (
    <CardContent>
      <Grid container justify="space-between">
        <Grid item>
          <Button size="large" onClick={() => setOpenFilters(!openFilter)}>
            Filtros {openFilter ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={openFilter} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={2}>
            <InputLabel id="demo-select-small">Comuna</InputLabel>
            <Autocomplete
              options={comunas}
              value={valueComuna}
              onChange={(event, newValue) => {
                setvalueComuna(newValue);
                filtersList["Comuna"] = newValue;
                console.log(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            ></Autocomplete>
            {/* <TextField
              id="outlined-basic"
              size="small"
              label="Nombre"
              variant="outlined"
              fullWidth
              value={valueNombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Usuario.Nombre"] = valueNombre;
                  if (!valueNombre) {
                    delete filtersList["Usuario.Nombre"];
                  }

                  if (Object.keys(filtersList).length > 0) {
                    dispatch(getSolicitudFiltered(filtersList));
                  } else {
                    dispatch(getSolicitudes());
                  }
                  ev.preventDefault();
                }
              }}
            /> */}
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="demo-select-small">Categoria</InputLabel>
            <Autocomplete
              options={categorias.map((categoria: any) => {
                return categoria.Nombre;
              })}
              value={valueCategoria}
              onChange={(event, newValue) => {
                setValueCategoria(newValue);
                filtersList["Categoria"] = newValue;
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            ></Autocomplete>
            {/* <TextField
              id="outlined-basic"
              size="small"
              label="Apellido"
              variant="outlined"
              fullWidth
              value={valueApellido}
              onChange={handleChangeApellido}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Usuario.Apellido"] = valueApellido;
                  if (!valueApellido) {
                    delete filtersList["Usuario.Apellido"];
                  }

                  if (Object.keys(filtersList).length > 0) {
                    dispatch(getSolicitudFiltered(filtersList));
                  } else {
                    dispatch(getSolicitudes());
                  }
                  ev.preventDefault();
                }
              }}
            /> */}
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="demo-select-small">Estado</InputLabel>
            <Select
              id="demo-select-small"
              labelId="demo-select-small"
              label="Estado"
              fullWidth
              variant="outlined"
              value={valueEstado}
              onChange={(event) => {
                setValueEstado(event.target.value);
                filtersList["Estado"] = event.target.value;
              }}
            >
              <MenuItem value="Bloqueado">Bloqueado</MenuItem>
              <MenuItem value="Archivada">Archivada</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="creacion-label">Fecha Creación</InputLabel>
            <TextField
              id="creacion-start"
              type="datetime-local"
              value={valueCreacionStart}
              onChange={(event) => {
                setValueCreacionStart(event.target.value);
                filtersList["startAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
            />
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            <TextField
              id="creacion-start"
              label="Fecha Creación: Final"
              size="small"
              type="datetime-local"
              value={valueCreacionEnd}
              onChange={(event) => {
                setValueCreacionEnd(event.target.value);
                filtersList["startAt"] = event.target.value;
              }}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={2}>
            <InputLabel id="demo-select-small">Destacado</InputLabel>
            <Select
              id="demo-select-small"
              fullWidth
              labelId="demo-select-small"
              label="Destacado"
              variant="outlined"
              value={valueDestacado}
              onChange={(event) => {
                setvalueDestacado(event.target.value);
                filtersList["Destacado"] = event.target.value;
              }}
            >
              <MenuItem value={"Si"}>Si</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
            {/* <TextField
              id="outlined-basic"
              size="small"
              label="Destacado"
              variant="outlined"
              fullWidth
              value={valueNombre}
              onChange={handleChangeNombre}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  filtersList["Usuario.Nombre"] = valueNombre;
                  if (!valueNombre) {
                    delete filtersList["Usuario.Nombre"];
                  }

                  if (Object.keys(filtersList).length > 0) {
                    dispatch(getSolicitudFiltered(filtersList));
                  } else {
                    dispatch(getSolicitudes());
                  }
                  ev.preventDefault();
                }
              }}
            /> */}
          </Grid>

          <Grid item xs={12} sm={1}>
            <Box display="flex">
              <Box mt={3} order={1}>
                <Tooltip title="Buscar">
                  <IconButton
                    size="medium"
                    aria-label="Filtrar"
                    onClick={() => {
                      /* if (valueNombre) {
                        filtersList["Usuario.Nombre"] = valueNombre;
                      }
                      if (valueApellido) {
                        filtersList["Usuario.Apellido"] = valueApellido;
                      } */
                      dispatch(getSolicitudFiltered(filtersList));
                    }}
                  >
                    <Search />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={3} order={2}>
                <Tooltip title="Limpiar">
                  <IconButton
                    size="medium"
                    aria-label="Borrar filtro"
                    onClick={() => {
                      //setValueNombre("");
                      //setValueApellido("");
                      setValueCreacionStart("");
                      //setValueCreacionEnd("");
                      setValueFiltersList({});
                      setValueEstado("");
                      setvalueDestacado("");
                      setvalueComuna("");
                      setValueCategoria("");
                      dispatch(getSolicitudes());
                    }}
                  >
                    <MinusSquare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </CardContent>
  );
};

export const Solicitudes = () => {
  return (
    <React.Fragment>
      <Helmet title="Solicitudes" />
      <Typography variant="h3" gutterBottom display="inline">
        Solicitudes
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/solicitudes">
          Listado
        </Link>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
