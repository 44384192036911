import { combineReducers } from "redux";
import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import { uiReducer } from "./uiReducer";
import { usersReducer } from "./usersReducer";
import { solicitudesReducer } from "./solicitudesReducer";
import { offersReducer } from "./offers.Reducer";
import { categoryReducer } from "./category.Reducer";
import { suscriptionReducer } from "./suscriptions.Reducer";
import  viewsReducer  from "./views.Reducer";
import { advertisingReducer } from "./advertising.Reducer";

export const rootReducer = combineReducers({
  themeReducer,
  uiReducer,
  authReducer,
  usersReducer,
  solicitudesReducer,
  offersReducer,
  categoryReducer,
  suscriptionReducer,
  viewsReducer,
  advertisingReducer
});

export type RootState = ReturnType<typeof rootReducer>;
