import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyDD7KKmOyu-PAMoV0HKpzrk_PG2gGJG1v8",
  authDomain: "demandapp-4cc44.firebaseapp.com",
  projectId: "demandapp-4cc44",
  storageBucket: "demandapp-4cc44.appspot.com",
  messagingSenderId: "114263255850",
  appId: "1:114263255850:web:55f79dbae2f323ef668c53",
  measurementId: "G-YK7WHSE11K",
};

export const app = firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
