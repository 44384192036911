import { Box, Button, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
interface Props {
  open: boolean;
  title: string;
  onClose: () => void;
  onAccept: (nombre: string, dias: string, monto: string) => void;
  value?: any;
  error?: boolean;
  helperText?: string;
  valor1?: string;
  valor2?: string;
  valor3?: string;
}
const useStyles = makeStyles((theme) => ({
  ventana: {
    borderRadius: "6%",
    position: "absolute",
    width: "35vw",
    backgroundColor: "white",
    border: "2 px solid #f8f4f4",
    boxShadow: theme.shadows[3],
    padding: "16px 32px 24px",
    top: "20%",
    left: "30%",
    transform: "translate(-35% - 35%) ",
  },
  textField: {
    width: "100%",
  },
}));

export const ModalSuscripcion: React.FC<Props> = ({
  open,
  title,
  onClose,
  onAccept,
  error,
  helperText,
  valor1 = "",
  valor2 = "",
  valor3 = "",
}) => {
  const classes = useStyles();
  const [nombre, setNombre] = useState("");
  const [dias, setDias] = useState("");
  const [monto, setMonto] = useState("");

  const body = (
    <div className={classes.ventana}>
      <div style={{ alignItems: "Flex-start" }}>
        <h4>{title}</h4>
      </div>
      <TextField
        placeholder="Ingresa el nombre de la Suscripcion"
        type="text"
        autoComplete="off"
        id="nombre"
        fullWidth={true}
        variant="outlined"
        value={nombre}
        onChange={(e) => {
          setNombre(e.target.value);
        }}
        error={error}
        helperText={helperText}
      />
      <br />
      <br />
      <TextField
        placeholder="Ingresa los Dias de Suscripcion"
        type="text"
        autoComplete="off"
        id="dias"
        fullWidth={true}
        variant="outlined"
        value={dias}
        onChange={(e) => {
          setDias(e.target.value);
        }}
        error={error}
        helperText={helperText}
      />
      <br />
      <br />
      <TextField
        type="text"
        placeholder="Ingresa el Monto de la Suscripcion"
        autoComplete="off"
        id="monto"
        fullWidth={true}
        variant="outlined"
        value={monto}
        onChange={(e) => {
          setMonto(e.target.value);
        }}
        error={error}
        helperText={helperText}
      />
      <br />
      <br />
      <Box display="flex" justifyContent="flex-end"  paddingTop="2%">
        {nombre && monto && dias !== "" ? (
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            onClick={() => {
              onAccept(nombre, dias, monto);
              onClose();
            }}
            color="primary"
          >
            Guardar
          </Button>
        ) : (
          error
        )}
        <Button variant="contained" onClick={onClose}>
          Cancelar
        </Button>
      </Box>
    </div>
  );

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        {body}
      </Modal>
    </div>
  );
};
