import Axios from "axios";
import firebase from "firebase/app";
import * as types from "../../constants";
import { firebaseConfig, firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { NewGoogleUserResponse } from "../../models/new_google_user_response";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";
import { openSnack } from "./uiActions";

export const getOffers = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.OFFERS_GET_SUBMMITING,
    });
    try {
      const response = await firestore
        .collectionGroup("Ofertas")
        .limit(limit)
        .orderBy(order, "desc")
        .get();
      const responseTotal = await firestore.collectionGroup("Ofertas").get();
      const responseOffers = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.OFFERS_GET_SUCCESS,
        payload: {
          offers: responseOffers,
          totalDocs: responseTotal.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.OFFERS_GET_FAILURE,
        payload: "Ha ocurrido un error al cargar las Solicitudes",
      });
    }
  };
};

export const getMoreOffers = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.OFFERS_GET_SUBMMITING,
    });
    const { offers, totalDocs, lastDoc } = getState().offersReducer;
    try {
      const response = await firestore
        .collectionGroup("Ofertas")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const offersList = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.OFFERS_GET_SUCCESS,
        offers: offers.concat(offersList),
        totalDocs: totalDocs,
        lastDoc: response.docs[response.docs.length - 1],
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.OFFERS_GET_FAILURE,
        payload: error,
      });
    }
  };
};
export const bloquedOffer = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.OFFERS_BLOQUED_SUBMMITING,
    });
    try {
      await firestore.collection("Ofertas").doc(id).update({
        Bloqueado: true,
      });
      dispatch(
        openSnack(
          "Se ha Bloqueado la solicitud exitosamente",
          SnackState.SUCCESS
        )
      );
    } catch (error: any) {
      dispatch({
        type: types.OFFERS_BLOQUED_FAILURE,
        payload: "Ha ocurrido un error al bloquear la oferta",
      });
    }
  };
};

export const UnlockedOffer = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.OFFERS_UNLOCKED_SUBMMITING,
    });
    try {
      await firestore.collection("Ofertas").doc(id).update({
        Bloqueado: false,
      });
      dispatch(
        openSnack(
          "Se ha Bloqueado la solicitud exitosamente",
          SnackState.SUCCESS
        )
      );
    } catch (error: any) {
      dispatch({
        type: types.OFFERS_UNLOCKED_FAILURE,
        payload: "Ha ocurrido un error al bloquear la Solicitud",
      });
    }
  };
};

export const getOneOffer = (id: any): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.OFFERS_GET_ONE_SUBMMITING,
    });
    try {
      const responseOffer = await firestore.collection("Ofertas").doc(id).get();

      dispatch({
        type: types.OFFERS_GET_ONE_SUCCESS,
        payload: responseOffer.data(),
      });
    } catch (error: any) {
      dispatch({
        type: types.OFFERS_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error al Obtener la oferta",
      });
    }
  };
};

export const getOffersFiltered = (
  filtro: any = {},
  order?: string,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      let query: any;

      if (order) {
        query = firestore.collectionGroup("Ofertas").orderBy("FechaCreacion");

        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];

          if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "Comuna"){
            console.log("entra")
            query.where("Usuario.Comuna", "==", value)
          } else {
            query = query.where(key, "==", value);
          }
        });
        const response = await query.get();
        const offersList = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.OFFERS_GET_SUCCESS,
          payload: {
            offers: offersList,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      } else {
        query = firestore.collectionGroup("Ofertas").orderBy("FechaCreacion");
        
        console.log(filtro)
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];
          if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "Comuna"){
            query = query.where("Usuario.Comuna", "==", value)
          } else {
            query = query.where(key, "==", value);
          }
        });
        const response = await query.get();
        console.log(response)
        const offerList = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.OFFERS_GET_SUCCESS,
          payload: {
            offers: offerList,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};
export const setSelectedOffer = (offer: any): Action => ({
  type: types.OFFERS_SET_SELECTED,
  payload: offer,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.OFFERS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.OFFERS_FAILURE,
  payload: error,
});
