import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";
import { openSnack } from "./uiActions";

export const getSuscriptions = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.SUSCRIPTIONS_GET_SUBMITING,
    });
    try {
      const response = await firestore
        .collection("Suscripciones")
        .limit(limit)
        .orderBy(order, "desc")
        .get();
      const responseTotal = await firestore.collection("Suscripciones").get();
      const respSuscripciones = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.SUSCRIPTIONS_GET_SUCCESS,
        payload: {
          suscriptions: respSuscripciones,
          totalDocs: responseTotal.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.SUSCRIPTIONS_GET_FAILURE,
        payload: "Ha ocurrido un error al cargar las Suscripciones",
      });
    }
  };
};

export const getMoreSuscrpciones = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.SUSCRIPTIONS_GET_SUBMITING,
    });
    const { suscriptions, totalDocs, lastDoc } = getState().suscriptionReducer;
    try {
      const response = await firestore
        .collection("Suscripciones")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const suscriptionsList = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      dispatch({
        type: types.SUSCRIPTIONS_GET_SUCCESS,
        suscriptions: suscriptions.concat(suscriptionsList),
        totalDocs: totalDocs,
        lastDoc: response.docs[response.docs.length - 1],
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.SUSCRIPTIONS_GET_FAILURE,
        payload: error,
      });
    }
  };
};
export const getOneSuscription = (id: any): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.SUSCRIPTIONS_GET_ONE_SUBMMITING,
    });
    try {
      const responseOffer = await firestore
        .collection("Suscripciones")
        .doc(id)
        .get();

      dispatch({
        type: types.SUSCRIPTIONS_GET_ONE_SUCCESS,
        payload: responseOffer.data(),
      });
    } catch (error: any) {
      dispatch({
        type: types.SUSCRIPTIONS_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error al Obtener la suscripcion",
      });
    }
  };
};

export function addSuscription(
  Nombre: string,
  dias: string,
  monto: string
): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.SUSCRIPTIONS_ADD_ONE_SUBMMITING,
    });
    try {
      await firestore.collection("Suscripciones").add({
        NombreSuscripcion: Nombre,
        Dias: dias,
        Monto: parseInt(monto),
        FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
        NombreSuscripcion_lower: cleanString(Nombre),
      });
      dispatch(
        openSnack(
          "Se ha agregado la Suscripcion exitosamente",
          SnackState.SUCCESS
        )
      );
      dispatch(getSuscriptions());
    } catch (error: any) {
      console.log(error);
      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.SUSCRIPTIONS_ADD_ONE_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}
export function updateSuscription(
  id: string,
  nombre: string,
  dias: string,
  monto: string
): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.SUSCRIPTIONS_UPDATE_ONE_SUBMMITING,
    });
    try {
      const response = await firestore
        .collection("Suscripciones")
        .doc(id)
        .update({
          NombreSuscripcion: nombre,
          Dias: dias,
          Monto: monto,
          FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
          FechaActualizacion: firebase.firestore.FieldValue.serverTimestamp(),
          NombreSuscripcion_lower: cleanString(nombre),
        });
      dispatch(getSuscriptions());
      dispatch(
        openSnack(
          "Se actualizo la Suscripcion exitosamente",
          SnackState.SUCCESS
        )
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        openSnack("Error inesperado Intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.SUSCRIPTIONS_UPDATE_ONE_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}
export const getSuscripcionFiltered = (
  filtro: any = {},
  order?: string,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      let query: any;

      if (order) {
        query = firestore.collection("Suscripciones").orderBy("FechaCreacion")
        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];

          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else {
            query = query.where(key, "==", value);
          }
        });
        const response = await query.get();
        const suscriptionsList = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.SUSCRIPTIONS_GET_SUCCESS,
          payload: {
            suscriptions: suscriptionsList,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      } else {
        query = firestore
          .collection("Suscripciones")
          .orderBy("FechaCreacion");

        Object.keys(filtro).forEach((key) => {
          let value = filtro[key];
          if (key === "endAt") {
            const miliDate = Date.parse(value);
            query = query.startAt(new Date(miliDate));
          } else if (key === "startAt") {
            const miliDate = Date.parse(value);
            query = query.endAt(new Date(miliDate));
          } else {
            query = query.where(key, "==", value);
          }
        });
        const response = await query.get();
        const suscriptionsList = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.SUSCRIPTIONS_GET_SUCCESS,
          payload: {
            suscriptions: suscriptionsList,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      dispatch(setError(error));
    } finally {
    }
  };
};
export const setSelectedSuscription = (suscription: any): Action => ({
  type: types.SUSCRIPTIONS_SET_SELECTED,
  payload: suscription,
});
const isLoading = (isloading: boolean): Action => ({
  type: types.OFFERS_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.OFFERS_FAILURE,
  payload: error,
});
