import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useHistory, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  ButtonGroup,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { ISuscription } from "../redux/reducers/suscriptions.Reducer";
import { getOneSuscription } from "../redux/actions/suscription.Actions";
import { timesStampFormattedsimple } from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = React.memo(() => {
  const dispatch = useDispatch();
  const { suscripcionId } = useParams<any>();

  const { selectedSuscription } = useSelector<RootState, ISuscription>(
    (state) => state.suscriptionReducer
  );
  useEffect(() => {
    if (!selectedSuscription) {
      dispatch(getOneSuscription(suscripcionId));
    }
  }, [selectedSuscription, suscripcionId]);
  return (
    <Card mb={6}>
      <CardContent
        style={{
          width: "100%",
          height: "40vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "40vh",
            backgroundColor: "#f5f5f5",
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              fontSize: 30,
              fontWeight: "bold",
              color: "#007ac9",
              textShadow: "1px 1px 1px",
            }}
          >
            {selectedSuscription?.NombreSuscripcion}
          </Typography>
          <Typography
            style={{ fontSize: 20, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Valido por {selectedSuscription?.Dias} Dias
          </Typography>
          <Typography
            style={{ fontSize: 18, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Precio :{selectedSuscription?.Monto} $CLP
          </Typography>
          <Typography
            style={{ fontSize: 16, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Fecha de Creación :{" "}
            {timesStampFormattedsimple(
              selectedSuscription?.FechaCreacion.toDate()
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
});

export const SuscriptionsView = () => {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Suscripción
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/suscripciones">
          Suscripciones
        </Link>
        <Typography>Detalle</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
