import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography
} from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../components/NewConfirmDialog";
import {
  bloquedOffer,
  getOneOffer,
  UnlockedOffer
} from "../redux/actions/offers.Actions";
import { IOffers } from "../redux/reducers/offers.Reducer";
import { RootState } from "../redux/reducers/rootReducer";
import { timesStampFormattedsimple } from "../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { offersId } = useParams<any>();
  const [openOcultar, setOpenOcultar] = useState(false);
  const [openPublicar, setOpenPublicar] = useState(false);

  const { selectedOffers, state } = useSelector<RootState, IOffers>(
    (state) => state.offersReducer
  );
  useEffect(() => {
    if (!selectedOffers) {
      dispatch(getOneOffer(offersId));
    }
  }, [selectedOffers, offersId]);
  return (
    <>
      <CardHeader
        style={{ margin: 0 }}
        action={
          <Box display="flex" flexDirection="flex">
            {selectedOffers?.Bloqueado === true ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpenPublicar(true);
                }}
              >
                Publicar
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpenOcultar(true);
                }}
              >
                Ocultar
              </Button>
            )}
          </Box>
        }
      />
      <Card margin={2} style={{ borderRadius: 10 }}>
        <CardContent style={{color:"#fff"}}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold", color: "#009fda", fontSize: 22 }}
            >
              {selectedOffers?.Titulo}
            </Typography>
            <Typography
              variant="h4"
              style={{ fontWeight: "bold", color: "#a4a7a9", fontSize: 18 }}
            >
             ({selectedOffers?.Descripcion})
            </Typography>

            <Box style={{ color: "#009fda", display: "flex", marginTop: 10 }}>
              <MonetizationOnIcon style={{ marginRight: 4 }} />
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", color: "#009fda", fontSize: 20 }}
              >
                {selectedOffers?.Precio}$CLP
              </Typography>
            </Box>
          </Box>
          <Grid style={{ marginTop: 20 }} container>
            <Grid style={{ marginBottom: 10 }} item xs={12} sm={12}>
              <Typography style={{ fontWeight: "bold", color: "#009fda",  fontSize:16}}>
                Descripcion:
              </Typography>
              <Typography style={{ fontWeight: "Bold", color: "#a4a7a9" }}>
                {selectedOffers?.Mensaje}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold", color: "#009fda", fontSize:16 }}>
                Nombre:
              </Typography>
              <Typography style={{ fontWeight: "Bold", color: "#a4a7a9" }}>
                {selectedOffers?.Usuario?.Nombre}{" "}
                {selectedOffers?.Usuario?.Apellido}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold", color: "#009fda", fontSize:16 }}>
                Región:
              </Typography>
              <Typography style={{ fontWeight: "Bold", color: "#a4a7a9" }}>
                {selectedOffers?.Usuario?.Region}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold", color: "#009fda",  fontSize:16 }}>
                Comuna:
              </Typography>
              <Typography style={{ fontWeight: "Bold", color: "#a4a7a9" }}>
                {selectedOffers?.Usuario?.Comuna}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "#009fda",
                  marginTop: 10,
                  marginBottom: 0,
                  fontSize:16
                }}
              >
                {" "}
                Fecha de creación , Inicio y Termino
              </Typography>

              <Typography style={{ fontWeight: "Bold", color: "#a4a7a9" }}>
                {" "}
                {`Creada el: 
                ${timesStampFormattedsimple(
                  selectedOffers?.FechaCreacion.toDate()
                )}`}
              </Typography>
              <Typography
                style={{
                  fontWeight: "Bold",
                  color: "#a4a7a9",
                  marginBottom: 5,
                }}
              >
                {" "}
                {`Inicia: 
                ${new Date(selectedOffers.FechaInicio).toLocaleDateString()}`}
              </Typography>
              <Typography
                style={{
                  fontWeight: "Bold",
                  color: "#a4a7a9",
                  marginBottom: 5,
                }}
              >
                {" "}
                {`Termina: 
                ${new Date(selectedOffers.FechaTermino).toLocaleDateString()}`}
              </Typography>
            </Grid>
          </Grid>
          <NewConfirmDialog
            open={openOcultar}
            onClose={() => setOpenOcultar(false)}
            title="¿Desea Ocultar esta Oferta?"
            onConfirm={() => {
              dispatch(bloquedOffer(offersId));
              history.push("/ofertas");
            }}
            onConfirmText={"Ocultar"}
          />
          <NewConfirmDialog
            open={openPublicar}
            onClose={() => setOpenPublicar(false)}
            title="¿Desea Publicar esta Oferta?"
            onConfirm={() => {
              dispatch(UnlockedOffer(offersId));
              history.push("/ofertas");
            }}
            onConfirmText={"Publicar"}
          />
        </CardContent>
      </Card>
    </>
  );
};

export const OffersView = () => {
  return (
    <React.Fragment>
      <Helmet title="Ofertas" />
      <Typography variant="h3" gutterBottom display="inline">
        Ver Oferta
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/ofertas">
          Ofertas
        </Link>
        <Typography>Detalle</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
