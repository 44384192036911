import firebase from "firebase";
import * as types from "../../constants";
import { firestore } from "../../firebase/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { SnackState } from "../../models/snack-state";
import { cleanString } from "../../utils/utils";
import { openSnack } from "./uiActions";
import { storage } from "../../firebase/firebase";
import { Advertising, ImageObj } from "../../models/Advertising";

export const getAdvertisings = (
  limit: number = types.TABLE_LIMIT_DEFAULT,
  order: string = "FechaCreacion"
): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.ADVERTISING_GET_SUBMITING,
    });
    try {
      const response = await firestore
        .collection("Publicidad")
        .limit(limit)
        .orderBy(order, "desc")
        .get();
      const responseTotal = await firestore.collection("Publicidad").get();
      const respAdvertising = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.ADVERTISING_GET_SUCCESS,
        payload: {
          advertisings: respAdvertising,
          totalDocs: responseTotal.size,
          lastDoc: response.docs[response.docs.length - 1],
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.ADVERTISING_GET_FAILURE,
        payload: "Ha ocurrido un error al cargar las Campañas Publicitarias.",
      });
    }
  };
};

export const getMoreAdvertisings = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ADVERTISING_GET_SUBMITING,
    });
    const { advertisings, totalDocs, lastDoc } = getState().advertisingReducer;
    try {
      const response = await firestore
        .collection("Publicidad")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      const advertisingList: Advertising[] = response.docs.map((x) => ({
        ...(x.data() as any),
        id: x.id,
      }));

      dispatch({
        type: types.ADVERTISING_GET_SUCCESS,
        advertisings: advertisings.concat(advertisingList),
        totalDocs: totalDocs,
        lastDoc: response.docs[response.docs.length - 1],
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.ADVERTISING_GET_FAILURE,
        payload: error,
      });
    }
  };
};

export const getOneAdvertising = (id: any): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.ADVERTISING_GET_ONE_SUBMITING,
    });
    try {
      const responseAdver = await firestore
        .collection("Publicidad")
        .doc(id)
        .get();

      dispatch({
        type: types.ADVERTISING_GET_ONE_SUCCESS,
        payload: responseAdver.data(),
      });
    } catch (error: any) {
      dispatch({
        type: types.ADVERTISING_GET_ONE_FAILURE,
        payload: "Ha ocurrido un error al obtener la campaña publicitaria",
      });
    }
  };
};

export type AddAdvertising = Omit<
  Advertising,
  "FechaCreacion" | "Activo" | "id" | "Imagen"
> & {
  fileDesktop?: File;
  fileMovil?: File;
};

export function addAdvertising(advertising: AddAdvertising): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.ADVERTISING_ADD_SUBMMITING,
    });
    try {
      if (!advertising.fileDesktop && !advertising.fileMovil) {
        throw new Error("Deben venir ambos banners");
      } else if (!advertising.fileDesktop) {
        throw new Error("Deben venir el banner para vista de escritorio");
      } else if (!advertising.fileMovil) {
        throw new Error("Deben venir el banner para vista de móvil");
      }
      const adverData: any = {
        ...advertising,
        FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
        Activo: true,
      };
      delete adverData.fileDesktop;
      delete adverData.fileMovil;

      const adverRef = await firestore.collection("Publicidad").add(adverData);

      const splitFileDesktop = advertising.fileDesktop.name.split(".");
      const splitFileMovil = advertising.fileMovil.name.split(".");

      const storageRefDesktop = storage.ref(
        `Publicidad/imagen_${Date.now()}.${
          splitFileDesktop[splitFileDesktop.length - 1]
        }`
      );

      const storageRefMovil = storage.ref(
        `Publicidad/imagen_movil_${Date.now()}.${
          splitFileMovil[splitFileMovil.length - 1]
        }`
      );

      await storageRefDesktop.put(advertising.fileDesktop);
      const publicUrlDesktop = await storageRefDesktop.getDownloadURL();
      const uploadDataDesktop = {
        URL: publicUrlDesktop,
        URI: storageRefDesktop.fullPath,
      };

      await storageRefMovil.put(advertising.fileMovil);
      const publicUrlMovil = await storageRefMovil.getDownloadURL();
      const uploadDataMovil = {
        URL: publicUrlMovil,
        URI: storageRefMovil.fullPath,
      };

      await adverRef.update({
        Imagen: uploadDataDesktop,
        ImagenMovil: uploadDataMovil,
      });

      dispatch({
        type: types.ADVERTISING_ADD_SUCCESS,
      });
      dispatch(
        openSnack(
          "Se ha agregado la Publicidad exitosamente",
          SnackState.SUCCESS
        )
      );
      dispatch(getAdvertisings());
    } catch (error: any) {
      console.log(error);
      dispatch(
        openSnack("Error inesperado, intentelo nuevamente.", SnackState.ERROR)
      );
      dispatch({
        type: types.ADVERTISING_ADD_FAILURE,
        payload: "Error Inesperado",
      });
    }
  };
}

export interface Docs {
  URI: string;
  URL: string;
}

export type UpdateAdvertising = Omit<
  Advertising,
  "FechaCreacion" | "Activo" | "Imagen"
> & {
  fileDesktop?: File;
  fileMovil?: File;
};
export function updateAdvertising(advertising: UpdateAdvertising): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.ADVERTISING_UPDATE_SUBMMITING,
    });
    try {

      await firestore.collection("Publicidad").doc(advertising.id).update({
        Titulo: advertising.Titulo,
        FechaInicio: advertising.FechaInicio,
        FechaTermino: advertising.FechaTermino,
        Vista: advertising.Vista,
        Posicion: advertising.Posicion,
        Link: advertising.Link,
        FechaCreacion: firebase.firestore.FieldValue.serverTimestamp(),
        FechaActualizacion: firebase.firestore.FieldValue.serverTimestamp(),
      });
      
      if (advertising.fileDesktop && Array.isArray(advertising.fileDesktop)) {
        for (const img of advertising.fileDesktop) {
          if (typeof img === "object") {
            if (img instanceof File) {
              const splitFile = advertising.fileDesktop[0].name.split(".");
              const storageRef = storage.ref(
                `Publicidad/imagen_${Date.now()}.${
                  splitFile[splitFile.length - 1]
                }`
              );
              await storageRef.put(img);
              const publicUrl = await storageRef.getDownloadURL();

              await firestore
                .collection("Publicidad")
                .doc(advertising.id)
                .update({
                  Imagen: {
                    URL: publicUrl,
                    URI: storageRef.fullPath,
                  },
                });
            }
          }
        }
      }

      if (advertising.fileMovil && Array.isArray(advertising.fileMovil)) {
        console.log("primer if movil");
        for (const img of advertising.fileMovil) {
          if (typeof img === "object") {
            console.log("segundo if movil");
            if (img instanceof File) {
              console.log("tercer if movil");
              const splitFile = advertising.fileMovil[0].name.split(".");
              const storageRef = storage.ref(
                `Publicidad/imagen_${Date.now()}.${
                  splitFile[splitFile.length - 1]
                }`
              );
              await storageRef.put(img);
              const publicUrl = await storageRef.getDownloadURL();
              await firestore
                .collection("Publicidad")
                .doc(advertising.id)
                .update({
                  ImagenMovil: {
                    URL: publicUrl,
                    URI: storageRef.fullPath,
                  },
                });
            }
          }
        }
      }

      dispatch({
        type: types.ADVERTISING_UPDATE_SUCCESS,
      });
      dispatch(getAdvertisings());
      dispatch(
        openSnack("Se actualizo la campaña exitosamente", SnackState.SUCCESS)
      );
    } catch (error: any) {
      console.log(error);
      dispatch(
        openSnack("Error inesperado, intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.ADVERTISING_UPDATE_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
}

export const setSelectedAdvertising = (advertising: any): Action => ({
  type: types.ADVERTISING_SET_SELECTED,
  payload: advertising,
});

export const unlockAdvertising = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.ADVERTISING_UNLOCK_SUBMITTING,
    });
    try {
      await firestore.collection("Publicidad").doc(id).update({
        Activo: true,
      });
      dispatch(
        openSnack(
          "Se ha Desbloqueado la publicidad exitosamente",
          SnackState.SUCCESS
        )
      );
      dispatch({
        type: types.ADVERTISING_UNLOCK_SUCCESS,
      });
    } catch (error: any) {
      console.log(error);
      dispatch(
        openSnack("Error inesperado, intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.ADVERTISING_UNLOCK_FAILURE,
        payload: "Ha ocurrido un error al bloquear la publicidad",
      });
    }
  };
};
export const lockAdvertising = (id: string): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.ADVERTISING_LOCK_SUBMITTING,
    });
    try {
      await firestore.collection("Publicidad").doc(id).update({
        Activo: false,
      });
      dispatch(
        openSnack(
          "Se ha bloqueado la publicidad exitosamente",
          SnackState.SUCCESS
        )
      );
      dispatch({ type: types.ADVERTISING_LOCK_SUCCESS });
    } catch (error: any) {
      console.log(error);
      dispatch(
        openSnack("Error inesperado, intentelo nuevamente", SnackState.ERROR)
      );
      dispatch({
        type: types.ADVERTISING_LOCK_FAILURE,
        payload: "Ha ocurrido un error al bloquear la publicidad",
      });
    }
  };
};
