import * as types from "../../constants";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface ISolicitudes {
  solicitudes: any[];
  reportes: any[];
  state: FormState;
  error?: string;
  totalDocs: any;
  lastDoc?: any;
  selectedSolicitud?: any;
  delete?: {
    state: FormState;
    error?: string;
  };
  edit?: {
    state: FormState;
    error?: string;
  };
  update?: {
    state: FormState;
    error?: string;
  };
}

const initialState = {
  solicitudes: [],
  reportes:[],
  state: FormState.Initial,
  totalDocs: 0,
  selectedSolicitud: null,
  delete: {
    state: FormState.Initial,
  },
  edit: {
    state: FormState.Initial,
  },
} as ISolicitudes;

export const solicitudesReducer = (
  state = initialState,
  action: Action
): ISolicitudes => {
  switch (action.type) {
    case types.SOLICITUDES_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        solicitudes: action.payload.solicitudes,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.SOLICITUDES_GET_SUBMMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.SOLICITUDES_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };

    case types.SOLICITUDES_SET_SELECTED:
      return {
        ...state,
        selectedSolicitud: action.payload,
      };
    case types.SOLICITUDES_DELETE_SUBMMINTING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.SOLICITUDES_DELETE_SUCCESS:
      return {
        ...state,
        delete: { state: FormState.Success },
        solicitudes: state.solicitudes.filter(
          (x) => x.id !== action.payload.id
        ),
      };
    case types.SOLICITUDES_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.SOLICITUDES_GET_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.SOLICITUDES_GET_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
        selectedSolicitud: action.payload,
      };
    case types.SOLICITUDES_GET_ONE_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: action.payload,
        },
      };
    case types.SOLICITUDES_GET_DOCS:
      return {
        ...state,
        solicitudes: action.payload,
      };
    case types.SOLICITUDES_SET_LAST_DOC:
      return {
        ...state,
        lastDoc: action.payload,
      };
    case types.SOLICITUDES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.SOLICITUDES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.SOLICITUDES_BLOQUED_SUBMMITING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
        },
      };
    case types.SOLICITUDES_BLOQUED_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.SOLICITUDES_BLOQUED_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
      };
    //
    case types.SOLICITUDES_UNLOCKED_SUBMMITING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
        },
      };
    case types.SOLICITUDES_UNLOCKED_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.SOLICITUDES_UNLOCKED_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
      };

      



      case types.REPORTES_GET_SUBMITING:
      return {
        ...state,
       
          state: FormState.Submitting,
          error: undefined,
      
      };
    case types.REPORTES_GET_SUCCESS:
      return {
        ...state,
        
          state: FormState.Success,
      
        reportes: action.payload,
      };
    case types.REPORTES_GET_FAILURE:
      return {
        ...state,
        
          state: FormState.Submitting,
          error: action.payload,
        
      };
    default:
      return state;
  }
};
