import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneAdvertising,
  lockAdvertising,
  unlockAdvertising
} from "../../redux/actions/advertising.Actions";
import { AdvertisingState } from "../../redux/reducers/advertising.Reducer";
import { RootState } from "../../redux/reducers/rootReducer";
import { timesStampFormattedsimple } from "../../utils/utils";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ContentCard = React.memo(() => {
  const dispatch = useDispatch();
  const { advertisingId } = useParams<any>();
  const [openBlocked, setOpenBlocked] = useState(false);
  const [openUnbloqued, setOpenUnblocked] = useState(false);
  const activo = green[300];
  const bloqueado = red[300];

  const { selectedAdvertising } = useSelector<RootState, AdvertisingState>(
    (state) => state.advertisingReducer
  );
  useEffect(() => {
    if (!selectedAdvertising) {
      dispatch(getOneAdvertising(advertisingId));
    }
  }, [selectedAdvertising, advertisingId]);

  const blockAdvertising = () => {
    console.log("bloquear");
    setOpenBlocked(true);
    dispatch(lockAdvertising(advertisingId));
  };

  const unblockAdvertising = () => {
    console.log(advertisingId);
    setOpenUnblocked(true);
    dispatch(unlockAdvertising(advertisingId));
  };
  return (
    <Card mb={6}>
      <CardHeader
        action={
          <Box display="flex">
            {selectedAdvertising?.Activo === true ? (
              <Button
                variant="contained"
                style={{ backgroundColor: bloqueado, marginRight: 5 }}
                onClick={() => {
                  blockAdvertising();
                }}
              >
                {" "}
                Bloquear Publicidad
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ backgroundColor: activo }}
                onClick={() => {
                  unblockAdvertising();
                }}
              >
                {" "}
                Desbloquear Publicidad
              </Button>
            )}
          </Box>
        }
      />
      <CardContent
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "100vh",
            backgroundColor: "#f5f5f5",
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              fontSize: 30,
              fontWeight: "bold",
              color: "#007ac9",
              textShadow: "1px 1px 1px",
            }}
          >
            {selectedAdvertising?.Titulo}
          </Typography>
          <Typography
            style={{ fontSize: 20, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Valido desde {selectedAdvertising?.FechaInicio} hasta{" "}
            {selectedAdvertising?.FechaTermino}.
          </Typography>
          <Typography
            style={{ fontSize: 18, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Vista :{selectedAdvertising?.Vista}.
          </Typography>
          <Typography
            style={{ fontSize: 18, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Posicion :{selectedAdvertising?.Posicion}.
          </Typography>
          <Typography
            style={{ fontSize: 18, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Link : {selectedAdvertising?.Link}
          </Typography>
          <Typography
            style={{ fontSize: 18, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Vista Escritorio:
            <img src={selectedAdvertising?.Imagen.URL} alt="" style={{width: 500, height: 200}} />
          </Typography>
          <Typography
            style={{ fontSize: 18, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Vista Móvil:
            <img src={selectedAdvertising?.ImagenMovil.URL} alt="" style={{width: 500, height: 200}} />
          </Typography>
          <Typography
            style={{ fontSize: 16, fontWeight: "bold", color: "#007ac9" }}
          >
            {" "}
            Fecha de Creación :{" "}
            {timesStampFormattedsimple(
              selectedAdvertising?.FechaCreacion.toDate()
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
});

export const AdvertisingsView = () => {
  return (
    <React.Fragment>
      <Helmet title="Detalle Campaña" />
      <Typography variant="h3" gutterBottom display="inline">
        Campaña publicitaria
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/publicidad">
          Campañas publicitarias
        </Link>
        <Typography>Detalle</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContentCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
