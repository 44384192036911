/* eslint-disable import/first */

// import async from "../components/Async";

import { Clipboard, Home, Users, Users as UsersIcon } from "react-feather";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import StyleIcon from "@material-ui/icons/Style";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PeopleIcon from "@material-ui/icons/People";
// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Guards
import AuthGuard from "../components/AuthGuard";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import ResetPassword from "../pages/auth/ResetPassword";
// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import { Blank } from "../pages/Blank";
import { CategoryList } from "../pages/CategoryList";
import { Offers } from "../pages/Offers";
import { OffersView } from "../pages/OffersView";
import { RestoreView } from "../pages/RestoreView";
import { Solicitudes } from "../pages/Solicitudes";
import { SolicitudesView } from "../pages/SolicitudesView";
import { Suscriptions } from "../pages/Suscriptions";
import { UserList } from "../pages/UserList";
import { ViewUser } from "../pages/ViewUser";
import { SuscriptionsView } from "../pages/SuscriptionsView";
import { EventNote } from "@material-ui/icons";
import { AdvertisingCampaign } from "../pages/AdvertisingCampaign";
import { AdvertisingsView } from "../pages/Advertising/AdvertisingView";

// RUTAS SIDEBAR
// const dashboardRoutes = {
//   id: "Home",
//   path: "/inicio",
//   icon: <Home />,
//   guard: AuthGuard,
//   component: Blank,
//   children: null,
// };

// const collapseRoutes = {
//   id: "Colapsable",
//   path: "/mypath",
//   icon: <Clipboard />,
//   children: [
//     {
//       path: "/mySubpath",
//       name: "Nombre",
//       guard: AuthGuard,
//       component: RestoreView,
//     },
//   ],
// };
const CategoryRoutes = {
  id: "Categorias",
  path: "/categorias",
  icon: <StyleIcon />,
  guard: AuthGuard,
  component: CategoryList,
  children: null,
};
const SuscripcionesRoutes = {
  id: "Suscripciones",
  path: "/suscripciones",
  icon: <CardMembershipIcon />,
  guard: AuthGuard,
  component: Suscriptions,
  children: null,
};
const AdvertisingRoutes = {
  id: "Publicidad",
  path: "/publicidad",
  icon: <EventNote />,
  guard: AuthGuard,
  component: AdvertisingCampaign,
  children: null,
};

const AdvertisingSubRoutes = {
  id: "detalle de campaña",
  path: "/detalleCampaña",
  icon: <Clipboard />,
  guard: AuthGuard,
  component: null,
  children: [
    {
      path: "/detalleCampaña/:advertisingId/detalle",
      name: "detalle",
      guard: AuthGuard,
      component: AdvertisingsView,
    },
  ],
};
const SuscripcionesSubRoutes = {
  id: "gestion de suscripciones",
  path: "/gestionSuscripciones",
  icon: <Clipboard />,
  guard: AuthGuard,
  component: null,
  children: [
    {
      path: "/gestionSuscripciones/:suscripcionId/detalle",
      name: "detalle",
      guard: AuthGuard,
      component: SuscriptionsView,
    },
  ],
};

const UsersRoutes = {
  id: "Usuarios",
  path: "/usuarios",
  icon: <PeopleIcon />,
  guard: AuthGuard,
  component: UserList,
  children: null,
};
const SolicitudesRoutes = {
  id: "Solicitudes",
  path: "/solicitudes",
  icon: <AssignmentIcon />,
  guard: AuthGuard,
  component: Solicitudes,
  children: null,
};
const OffersRoutes = {
  id: "Ofertas",
  path: "/ofertas",
  icon: <LocalOfferIcon />,
  guard: AuthGuard,
  component: Offers,
  children: null,
};
const OffersSubRoutes = {
  id: "Gestion de Ofertas",
  path: "/gestionOfertas",
  icon: <Clipboard />,
  guard: AuthGuard,
  component: null,
  children: [
    {
      path: "/gestionOfertas/:offersId/detalle",
      name: "detalle",
      guard: AuthGuard,
      component: OffersView,
    },
  ],
};
const SolicitudesSubRoutes = {
  id: "Gestion de Solicitudes",
  path: "/gestionSolicutudes",
  icon: <Clipboard />,
  guard: AuthGuard,
  component: null,
  children: [
    {
      path: "/gestionSolicutudes/:solicitudId/detalle",
      name: "detalle",
      guard: AuthGuard,
      component: SolicitudesView,
    },
  ],
};
// SUB RUTAS
const UsersSubRoutes = {
  id: "Usuarios sub routes",
  path: "/usuarios",
  icon: <UsersIcon />,
  children: [
    {
      path: "/ListaUsuarios/:userId/ver",
      name: "Ver",
      guard: AuthGuard,
      component: ViewUser,
    },
  ],
};

const dashboardSubRoutesRoutes = {
  id: "Dashboard sub routes",
  path: "/inicio",
  guard: AuthGuard,
  children: [
    {
      path: "/inicio/user/restorepassword",
      name: "Restore",
      guard: AuthGuard,
      component: RestoreView,
    },
  ],
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <UsersIcon />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Todas las rutas creadas deben ser protegidas
// Routes that are protected
export const protectedRoutes = [
  dashboardSubRoutesRoutes,
  UsersRoutes,
  UsersSubRoutes,
  SolicitudesRoutes,
  SolicitudesSubRoutes,
  OffersRoutes,
  OffersSubRoutes,
  CategoryRoutes,
  SuscripcionesRoutes,
  SuscripcionesSubRoutes,
  AdvertisingRoutes,
  AdvertisingSubRoutes
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  UsersRoutes,
  SolicitudesRoutes,
  OffersRoutes,
  CategoryRoutes,
  SuscripcionesRoutes,
  AdvertisingRoutes,
];
