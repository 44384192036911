import { Action } from "../../models/action";
import * as types from "../../constants";
import { FormState } from "../../models/form_state";
import { Advertising } from "../../models/Advertising";

export interface AdvertisingState {
  advertisings: Advertising[];
  state: FormState;
  totalDocs: number;
  selectedAdvertising?: any;
  lastDoc?: any;
  error?: string;
  add?:{
    state: FormState;
    error?: string;
  };
  edit: {
    state: FormState;
    error?: string;
  };
  delete?:{
    state: FormState;
    error?: string
  };
}

const initialState = {
  advertisings: [],
  state: FormState.Initial,
  totalDocs: 0,
  selectedAdvertising: null,
  add: {
    state: FormState.Initial
  },
  edit: {
    state: FormState.Initial
  },
  delete: {
    state: FormState.Initial
  },
} as AdvertisingState;

export const  advertisingReducer = (
  state = initialState,
  action: Action
): AdvertisingState => {
  switch (action.type) {
    case types.ADVERTISING_GET_SUBMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.ADVERTISING_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        advertisings: action.payload.advertisings,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.ADVERTISING_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload
      }
    case types.ADVERTISING_SET_SELECTED:
      return {
        ...state,
        selectedAdvertising: action.payload,
      };
    case types.ADVERTISING_LOCK_SUBMITTING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.ADVERTISING_LOCK_SUCCESS:
      return {
        ...state,
        delete: { 
          state: FormState.Success
        },
      };
    case types.ADVERTISING_LOCK_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
      case types.ADVERTISING_UNLOCK_SUBMITTING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.ADVERTISING_UNLOCK_SUCCESS:
      return {
        ...state,
        delete: { 
          state: FormState.Success
        },
      };
    case types.ADVERTISING_UNLOCK_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.ADVERTISING_ADD_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.ADVERTISING_ADD_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.ADVERTISING_ADD_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.ADVERTISING_UPDATE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.ADVERTISING_UPDATE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success
        },
      };
    case types.ADVERTISING_UPDATE_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.ADVERTISING_GET_ONE_SUBMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.ADVERTISING_GET_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
        selectedAdvertising: action.payload,
      };
    case types.ADVERTISING_GET_ONE_FAILURE: 
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}