import { Action } from "../../models/action";
import * as types from "../../constants";
import { FormState } from "../../models/form_state";

export interface ISuscription {
  suscriptions: any[];
  state: FormState;
  totalDocs: number;
  selectedSuscription?: any;
  delete?: {
    state: FormState;
    error?: string;
  };
  lastDoc?: any;
  error?: string;
  add?: {
    state: FormState;
    error?: string;
  };
  edit: {
    error?: string;
    state: FormState;
  };
}

const initialState = {
  suscriptions: [],
  state: FormState.Initial,
  totalDocs: 0,
  selectedSuscription: null,
  edit: {
    state: FormState.Initial,
    isLoading: false,
  },
} as ISuscription;

export const suscriptionReducer = (
  state = initialState,
  action: Action
): ISuscription => {
  switch (action.type) {
    case types.SUSCRIPTIONS_GET_SUBMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.SUSCRIPTIONS_GET_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        suscriptions: action.payload.suscriptions,
        totalDocs: action.payload.totalDocs,
        lastDoc: action.payload.lastDoc,
      };
    case types.SUSCRIPTIONS_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };

    case types.SUSCRIPTIONS_SET_SELECTED:
      return {
        ...state,
        selectedSuscription: action.payload,
      };
    case types.SUSCRIPTIONS_DELETED_SUBMMITING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.SUSCRIPTIONS_DELETED_SUCCESS:
      return {
        ...state,
        delete: { state: FormState.Success },
        suscriptions: state.suscriptions.filter(
          (x) => x.id !== action.payload.id
        ),
      };
    case types.SUSCRIPTIONS_DELETED_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.SUSCRIPTIONS_GET_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.SUSCRIPTIONS_GET_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
        selectedSuscription: action.payload,
      };
    case types.SUSCRIPTIONS_GET_ONE_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.SUSCRIPTIONS_ADD_ONE_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.SUSCRIPTIONS_ADD_ONE_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.SUSCRIPTIONS_ADD_ONE_SUBMMITING:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.SUSCRIPTIONS_UPDATE_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.SUSCRIPTIONS_UPDATE_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
      };
    case types.SUSCRIPTIONS_UPDATE_ONE_FAILURE:
      return {
        ...state,
        edit: { state: FormState.Failure, error: action.payload },
      };
    default:
      return state;
  }
};
